.gwRowLayout {
    margin-bottom: var(--GW-LAYOUT-6);
}

.gwProducerCodeDivider {
    height: calc(0.6 * var(--GW-HEADER-HEIGHT-SM));
    border-left: solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH);
}

.gwClaimsLinkContent {
    text-decoration: none;
    color: var(--GW-LINK-COLOR);
}

.gwClaimsAccountIcon {
    padding-right: var(--GW-SPACING-2);
    color: var(--GW-LINK-COLOR);
}

.currencyContainer {
  div[class*="fieldComponent"] {
    padding-bottom: 0;
  }
}
.linkAccount {
    display: flex;
    align-items: center;
}

.gwTitleStyle {
    padding-bottom: var(--GW-SPACING-4);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}
.claimsTableContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
}