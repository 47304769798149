@import "~@jutro/theme/assets/sass/helpers";

.wcInjuryContainer {
  .wcInjuryDetail {
    @include gw-breakpoint-up(desktop) {
      margin-left: var(--GW-LAYOUT-8);
    }
  }

    .wcAddInjuryDeleteIcon {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}