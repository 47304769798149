@import "~@jutro/theme/assets/sass/helpers";

.paClaimVehicle {
    .paClaimAddVehicle {
        --pa-claim-add-vehicle-width: 170px;
        width: var(--pa-claim-add-vehicle-width);
    }

    .paPointOfImpactSelect {
        border: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    }

    .chevronStyle {
        --gw-chevron-padding-top-bottom: 15px;
        padding: var(--gw-chevron-padding-top-bottom) 0;
    }

    .deleteRow {
        margin-top: 0;
    }

    .paVehicalCardBody {
        --gw-vehicle-card-body-margin: 10px 0 0;
        margin: var(--gw-vehicle-card-body-margin);
    }

    .paAddPassengerTableContainer:empty {
        padding-bottom: var(--GW-LAYOUT-6);
    }

    .paAddPassengerTable {
        @include gw-breakpoint-down(phonewide) {
            --table-padding-x: 0;
        }
    }

    .accordionHeader {
        width: 100%;
        display: flex;
        align-items: center;

        .accordionHeaderTitle {
            margin-left: var(--GW-SPACING-5);
        }
    }

    .accordionHeaderSeparater {
        justify-content: space-between;
    }
}
