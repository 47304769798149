.buttonFileAdditional {
	display: none;
}

.claimscontainer {
	div.fileUploadField {
		color: var(--GW-LINK-COLOR);
		margin-left: var(--gw-additional-claims-container-margin-left);
		display: inline-block;
	}
	.i {
		padding-right: var(--gw-additional-notify-fas-padding-right);
	}
}

.additionalAccordioncontainer {
	--gw-state-accordion-margin: var(--GW-LAYOUT-3) 0 0;

	margin: var(--GW-LAYOUT-2) 0;

	[class*='cardBody'] {
		margin: var(--gw-state-accordion-margin)
	}
}

.claimsTablecontainer {
	i {
		margin-right: var(--GW-SPACING-2);
		display: inline-block;
	}
}

.spreadSheetContainer {
	display: flex;
	justify-content: flex-end;
}

.selectEmployeesClassesContinue {
	--gw-additional-notify-padding: var(--GW-LAYOUT-4);
	--gw-additional-notify-height: 38px;
	--gw-additional-notify-margin: var(--GW-LAYOUT-5) 0;

    background-color: var(--GW-COLOR-WARNING);
	display: flex;
    flex-direction: row;
    margin: var(--gw-additional-notify-margin);
    padding: var(--gw-additional-notify-padding);
    border: var(--GW-BORDER-WIDTH) solid transparent;
    border-radius: var(--GW-BORDER-RADIUS);
}

.assignContainer {
	--gw-assign-margin: var(--GW-LAYOUT-8) 0;
	margin: var(--gw-assign-margin);
}

.warningIcon {
	margin: var(--GW-SPACING-5) var(--GW-SPACING-3);
}

.removeLinkStyle {
    text-decoration: none;
    margin-bottom: 2.5px;
    color: var(--GW-LINK-COLOR);
    [class*='text'] {
		padding-bottom: 0;
      &:hover {
        text-decoration: none;
      }
    }
  }

.warningStyle {
  	padding: 0px var(--GW-SPACING-3);
}

.employeeAndClassesCardBody {
	margin: var(--gw-state-accordion-margin);
}

.editLocation {
	padding: var(--GW-LAYOUT-8) var(--GW-LAYOUT-12);
}
.saveButtonContainer,
.employeesClassesSaveButtonContainer {
	display: flex;
	justify-content: flex-end;
}
.employeesClassesCancelButton {
	margin-right: var(--GW-SPACING-8);
}
.wc7UpdatedLocationTableContainer {
	.displayNameColumn {
		white-space: unset;
		overflow: unset;
		.displayName {
			color: var(--GW-LINK-COLOR);
			cursor: pointer;
			&:hover {
				text-decoration: none;
			}
		}
	}
}
.chevronStyle.chevronStyle {
	--chevron-margin-y: var(--GW-SPACING-1);
}
.addressLookupStyle {
	display: block;
}