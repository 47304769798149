@import "~@jutro/theme/assets/sass/helpers";

.caClaimVehicle {
    .claimsCAContainerPage {
        @include gw-breakpoint-down(tablet) {
            --ca-claim-container-width: 20px;
            width:var(--ca-claim-container-width);
        }
    }

    .caClaimAddVehicle {
        --pa-claim-add-vehicle-width: 14rem;
        --pa-claim-add-vehicle-margin-top-bottom: 4rem;
        width: var(--pa-claim-add-vehicle-width);
        margin: var(--pa-claim-add-vehicle-margin-top-bottom) 0;
    }

    .caPointOfImpactLabel {
        display: flex;
        align-items: center;
    }

    .caPointOfImpactSelect {
        border: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    }

    .chevronStyle {
        --gw-chevron-padding-top-bottom: 15px;
        padding: var(--gw-chevron-padding-top-bottom) 0;
    }

    .caVehicleIncidentDeleteIcon i {
        color: var(--GW-COLOR-SUCCESS);
    }

    .vehicleAccordion {
        --accordion-card-body-margin-x: var(--GW-LAYOUT-5);
    }

    .caAddPassengerTableContainer:empty {
        padding-bottom: var(--GW-LAYOUT-6);
    }

    .accordionHeader {
        width: 100%;
        display: flex;
        align-items: center;

        .accordionHeaderTitle {
            margin-left: var(--GW-SPACING-5);
        }
    }

    .accordionHeaderSeparater {
        justify-content: space-between;
    }
}