.gw-branding-gateway-cobranded {
    --gw-application-header-height: 75px;
    body {
        button {
            --GW-BRAND-COLOR-1: var(--gw-blue-light-14);
            --GW-BRAND-COLOR-1-ACTIVE: var(--gw-blue-light-14);
            --GW-BRAND-COLOR-1-HOVER: var(--gw-blue-light-14);
        }
        a {
          color: var(--gw-blue-light-14);
          text-decoration: none;
        }
        a span:active,a span:hover{
            --GW-TEXT-DECORATION-UNDERLINE: none;
        }
        .jut__Button__tertiary{
            --GW-BRAND-COLOR-1: var(--gw-lime-green);
        }
        .buttonFileUpload button{
            --button-bg-color: var(--gw-blue-light-14);
            background-image: none;
            --button-border-color: none;
        }
        .digitalToggleButton {
            --toggleButton-color: var(--gw-grey-light-60-color);
            background-image: var(--toggleButton-bg-image);
            background-color: var(--toggleButton-bg-color);
        }
        #adjusterTitleDiv h2 {
            --gw-millennials-accordion-toggle-border-spacing: 2px;
            border-bottom: var(--gw-millennials-accordion-toggle-border-spacing) solid var(--gw-blue-light-14);
        }
        .actionIcon{
            --GW-BRAND-COLOR-1: var(--gw-lime-green);
            --GW-BRAND-COLOR-1-ACTIVE: var(--gw-green-2);
            --GW-BRAND-COLOR-1-HOVER: var(--gw-green-2);
        }
    }

    #applicationHeader {
        height: var(--gw-application-header-height);
        background-color: var(--gw-white);
        span, a, i, select {
            color: var(--gw-blue-light-14);
        }
    }

    .ReactTable .rt-thead {
        background-color: var(--gw-blue-light-20);
    }
    .ReactTable a, .ReactTable button{
        color: var(--gw-blue-light-20);
    }
    div[class^='MenuBarComponent_menuBar'] {
        --gw-menu-bar: var(--gw-blue-light-14);
        a {
            color: var(--gw-white);
        }
    }
    .gwTilesColor {
        background-color: var(--gw-blue-light-20);
    }
    .gwTileMainContent {
        a {
            color: var(--gw-grey-light-9);
        }
    }
    #newQuoteButton {
        --GW-BRAND-COLOR-1: var(--gw-lime-green);
        --GW-BRAND-COLOR-1-ACTIVE: var(--gw-green-2);
        --GW-BRAND-COLOR-1-HOVER: var(--gw-green-2);
    }

    div[class^='QuoteClauseTable_quoteTableHeaderSection'] a{
        color: var(--gw-blue-light-8);
    }
    .producerCodesBlock .inputFieldThin a, .jumpToNewest,.printConfirmationDiv a{
        color: var(--gw-blue-light-8);
    }
    #detailGridPolicyDeclationLinkId a,
    #detailGridPolicyIdCardLinkId a,
    #pageHeaderPolicyItem a,
    #useSpreadsheetDisclaimerContainer a,
    #successDetailsPage a,
    #spreadsheetProcessor a,
    #editCoveragesLink,
    #employeesAndLocationContainer a,
    #policyInfoLink a{
        color: var(--gw-blue-light-8);
    }
    .clauseCategoryContainer button {
        color: var(--gw-blue-light-2);
    }

}