:global(.themeRoot) .gwPageTitle {
	margin: 0 var(--GW-SPACING-3);

	.gwPageAssociated {
		text-transform: uppercase;
	}
}

.gwSidePageTitle {
	display: flex;

	.gwSidePageCategory {
		padding-right: var(--GW-LAYOUT-2);
	}
	.gwSidePageTitleSeparator {
		margin-top: var(--GW-SPACING-1);
		border: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
		margin-right: var(--GW-SPACING-2);
	}
	.gwSidepageTitle {
		padding-left: var(--GW-SPACING-3);
	}
}

.gwTypeWithLOB {
	display: flex;
	margin-top: var(--GW-LAYOUT-2);
	align-items: center;

	.syncIcon {
		background: var(--GW-COLOR-WARNING);
		text-align: center;
		padding: var(--GW-SPACING-3);
		border-radius: 50%;
		color: var(--GW-TEXT-COLOR-3);
	}
}

.gwHeaderLink {
	cursor: pointer;
	color: var(--GW-LINK-COLOR);
	text-decoration: none;
}