.tableContainer {
  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
  margin-bottom: var(--GW-LAYOUT-6);

  .gwTitleStyle {
    padding-bottom: var(--GW-LAYOUT-2);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    margin-bottom: var(--GW-LAYOUT-2);
  }
}

.gwSectionTitle {
  --gw-contacts-header-page-bottom-line: 2px;
  border-bottom: var(--gw-contacts-header-page-bottom-line) solid var(--GW-BORDER-COLOR);
  padding-bottom: var(--GW-LAYOUT-3);
}
