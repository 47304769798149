@import "~@jutro/theme/assets/sass/helpers";
:root{
	--gw-timeline-details-indent-desktop: var(--GW-LAYOUT-5) var(--GW-LAYOUT-3) 0 var(--GW-LAYOUT-5);
	--gw-timeline-transition-duration: 0.5s;
}
.gwTimelineDetails {
	--gw-timeline-indent-padding: var(--GW-LAYOUT-3) var(--GW-LAYOUT-3) 0 var(--GW-LAYOUT-3);
	--gw-timeline-head-border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	background-color: var(--GW-BACKGROUND-COLOR-BODY);
	color: var(--GW-COLOR-WARNING-CONTRAST);
	border-radius: var(--GW-BORDER-RADIUS);
	border-top-left-radius: 0;
	border-top-right-radius: 0;
	display: flex;
	will-change: height;
	transition: none;
	flex-wrap: wrap;
	width: 100%;
	&--closed {
		height: 0;
	}
	&__animation_allowed {
		transition: height ease var(--gw-timeline-transition-duration);
	}
	&__head {
		width: 100%;
		margin: var(--gw-timeline-indent-padding);
		padding-bottom: var(--GW-LAYOUT-3);
		border-bottom: var(--gw-timeline-head-border-bottom);
	}
	&__head>div:not(:last-child) {
		margin-bottom: var(--GW-LAYOUT-3);
	}
	&__body {
		padding: var(--gw-timeline-indent-padding);
		display: flex;
		flex-flow: column wrap;
		width: 100%;
	}
}

.EventDetailsVisible {
	display: flex;
}

.EventDetailsInVisible {
	display: none;
}

@include gw-breakpoint-only(tablet) {
	.gwTimelineDetails {
		&__body {
			padding: var(--GW-LAYOUT-5);
			flex-direction: row
		}
		&__head {
			margin: var(--GW-LAYOUT-5);
			padding-bottom: var(--GW-LAYOUT-5);
		}
		&__head>div:not(:last-child) {
			margin-bottom: var(--GW-LAYOUT-5);
		}
	}
}
