:root {
    --gw-display-nomessage-margin-top: 8px;
    --gw-display-nomessage-margin-left: 6px;
    --gw-statement-left-width: 30px;
    --gw-statement-items-container-padding: 16px;
    --gw-statement-items-container-margin: 0 8px 8px 0;
    --gw-statement-items-container-width: 194px;
    --gw-total-header-margin: 0 16px 0 0;
    --gw-yeardropdown-width: 230px;
}

.gwCommissionPageTileContainer {
    padding-top: var(--GW-LAYOUT-3);
}

.mtdGridLeft,
.ytdSummaryGridRight,
.bookedPolicies {
    background: var(--GW-BACKGROUND-COLOR);
    border-radius: var(--GW-BORDER-RADIUS);
    box-shadow: var(--GW-SHADOW-2);
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
}

.summarySeparator {
    width: 100%;
    border-top: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR);
}

.totalCommisionDue {
    font-weight: var(--GW-FONT-WEIGHT-MEDIUM);
}

.mtdSummaryGrid {
    margin-bottom: var(--GW-LAYOUT-6);
    .commissionCurrencyField {
        width: 100%;
        :global(.jut__FieldLabel__left) {
            flex: 4 4;
        }
    }
}

.gwRowLayout {
   margin-bottom: var(--GW-LAYOUT-6);
}

.gwProducerCodeDivider {
    --gw-producer-code-divider-height: 36px;

    height: var(--gw-producer-code-divider-height);
    border-left: solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH);
}

.gwRemoveInputLabel {
    grid-template-columns: 0fr auto;
}

.seperator {
    margin-bottom: var(--gw-separator-margin-bottom);
}

.yearDropDown {
    width: var(--gw-yeardropdown-width);
}

.statementTileContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
}

.statementHeader {
    border-bottom: var(--GW-BORDER-WIDTH)  solid var(--GW-BORDER-COLOR);
    padding: 0 0 var(--GW-LAYOUT-3) 0;
    margin: 0 0 var(--GW-LAYOUT-5) 0;
    justify-content: space-between;

    .selectStatementHeader{
        display: flex;
        align-items: flex-end;
    }
}

.statementItems {
    display: flex;
    flex-wrap: wrap;
}

.statementItemsContainer {
    padding: var(--gw-statement-items-container-padding);
    border: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
    border-radius: var(--GW-BORDER-RADIUS);
    margin: var(--gw-statement-items-container-margin);
    width: var(--gw-statement-items-container-width);
    cursor: pointer;
    display: flex;
    [class*='fieldComponent'] {
        padding-bottom: 0;
    }
}

.statementItemsContainer:hover {
    transition: background-color .25s ease;
    background-color: var(--GW-BACKGROUND-COLOR-ELEMENT-HOVER);
}

.statementLeft {
    width: var(--gw-statement-left-width);
    display: flex;
    align-items: center;
}

.statementTableHeader {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    padding: var(--GW-LAYOUT-4) 0;
    margin: var(--GW-LAYOUT-4) 0;
}

.statementBalanceContainer {
    display: flex;
    justify-content: flex-end;
}

.totalHeader {
    margin: var(--gw-total-header-margin);
}

.negativeCommission {
    [class*='CurrencyField'] {
        color: var(--GW-COLOR-ERROR);
    }
}

.statementSelectedTile {
    border: 1px solid var(--GW-LINK-COLOR-HOVER);
    background-color: var(--GW-LINK-COLOR-HOVER);

    &:hover {
        background-color: var(--GW-LINK-COLOR-HOVER);
    }
    .statementDate,
    .statementItemSecond,
    .fileIconClass,
    [class*='datePicker'],
    [class*='currency'] {
        color: var(--GW-BACKGROUND-COLOR);
    }
}

.statementTotal {
    text-align: right;
}
