:root {
  --gw-button-padding: var(--GW-SPACING-8);
  --gw-page-section-header-row-new-quote-section-width: 100%;
  --gw-button-container-height: 8rem;
}

.headerSectionRow {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
  text-decoration: none;
  cursor: pointer;
}

.headerSectionRowIssuedPolicies {
  padding-top: var(--gw-page-section-header-row-padding-top);
}

.headerNewQuoteSection {
  display: flex;
  width: var(--gw-page-section-header-row-new-quote-section-width);
  justify-content: space-between;
}

.headerTextStyle {
  text-align: center;
}

.tableCellStyle {
  border-top: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.gwButtonStyle {
  width: var(--gw-page-section-header-row-new-quote-section-width);
  height: var(--gw-button-container-height);
  background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
  text-align: right;
  padding-right: var(--gw-button-padding);
  padding-top: var(--gw-button-padding);
}

.accordionStyle {
  --gw-accordion-heading-padding: calc(3 * var(--GW-SPACING));
  color: var(--GW-LINK-COLOR);
  padding-left: var(--gw-accordion-heading-padding);
  text-align: left;

  .headerStyle {
    text-transform: uppercase;
    color: var(--GW-TEXT-COLOR-2);
    padding-left: var(--GW-LAYOUT-8);
  }

  .tableStyle {
    width: var(--gw-page-section-header-row-new-quote-section-width);
    border-spacing: var(--gw-page-section-header-row-border-bottom);
    border-color: var(--GW-BORDER-COLOR);
    background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
    box-sizing: inherit;
    vertical-align: middle;
    table[class*='tableElement'] {
      background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
    }
  }

  .tableHeaderStyle {
    display: none;
  }

  .otherPremiumAndSurchargesTableStyle {
    width: var(--gw-page-section-header-row-new-quote-section-width);
    border-spacing: var(--gw-page-section-header-row-border-bottom);
    border-color: var(--GW-BORDER-COLOR);
    background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
    box-sizing: inherit;
    vertical-align: middle;
    padding-bottom: var(--GW-LINE-HEIGHT-LG);
  }
  .changeOrAddEmployeeButtonIdContainer {
    --gw-container-height: 47px;
    width: var(--gw-page-section-header-row-new-quote-section-width);
    border-spacing: var(--gw-page-section-header-row-border-bottom);
    border-color: var(--GW-BORDER-COLOR);
    background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
    box-sizing: inherit;
    vertical-align: middle;
    padding-bottom: var(--GW-LINE-HEIGHT-LG);
    height: var(--gw-container-height);
  }
}

.gwPageSectionHeaderRowLink{
  text-decoration: none;
  color: var(--GW-LINK-COLOR);
  white-space: nowrap;
}

.addOrChangeContainerStyle {
  white-space: nowrap;
}

.wc7PageContainerStyle {
  --gw-left-padding: calc(2.5 * var(--GW-SPACING));
  padding-left: var(--gw-left-padding);
}

.tax {
  grid-template-columns: 0 1fr;
  text-align: left;
}

.chevronStyle.chevronStyle {
  --chevron-margin-y: var(--GW-SPACING-6);
  color: var(--GW-LINK-COLOR);
}

.currencyContainer {
  div[class*='fieldComponent'] {
    padding-bottom: 0;
  }
}
