@import "~@jutro/theme/assets/sass/helpers";

:root {
    --gw-dashboard-search-margin: 15px 0 65px;
    --gw-dashboard-search-box-height: 65px;
    --gw-dashboard-search-min-width: 520px;
    --gw-dashboard-search-btn-padding: 10px 15px;
    --gw-dashboard-search-btn-min-width: 150px;
    --gw-dashboard-no-results-padding-left: 10px;
}

.titleContainer {
    --gw-dashboard-title-wrapper-width: 100%;
    width: var(--gw-dashboard-title-wrapper-width);
}

.startNewQuote {
    flex: 1;
}

.searchResultsContainer {
    margin-bottom: var(--GW-LAYOUT-5);
    [class*='searchResultsTitle'] {
        padding-bottom: var(--gw-sub-title-padding-bottom);
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
        font-size: var(--GW-DISPLAY-FONT-SIZE-XL);
        font-weight: var(--GW-DISPLAY-FONT-WEIGHT);
    }
}
.recentlyVisitedPolicies {
    margin-top: var(--GW-LAYOUT-6);
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
}

.recentlyVisitedAccounts {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
}

.noResultsFound {
  font-size: var(--GW-FONT-SIZE);
  font-weight: normal;
  margin-top: var(--GW-LAYOUT-5);
  background-color: transparent;
}

.mediaSideBarContainer {
    top: var(--gw-media-side-bar-top);
    right: 0;
}
.recentsTable {
    padding: 0;
    @include gw-breakpoint-down(tablet) {
        --gw-table-spacing: 0.35rem;
    }
}
