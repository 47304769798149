
:root {
  --gw-tiles-heading-margin-left: 10px;
  --gw-divider-border: 1px;
  --line-margin-bottom: 24px;
  --gw-noMessage-margin-top: 20px;
}
.landingStyles {
  .tilesHeading {
    margin-left: var(--gw-tiles-heading-margin-left);
  }

  .tilesContainer {
    padding-top: var(--GW-LAYOUT-5);
    padding-bottom: var(--GW-LAYOUT-6);
  }

  .gwRemoveInputLabel {
    grid-template-columns: 0fr auto;
  }

  .gwProducerCodeDivider {
    border-left: solid var(--gw-divider-border) var(--GW-SEPARATOR-COLOR);
    height: calc(0.6 * var(--GW-HEADER-HEIGHT-SM));
  }

  .landingContainer {
    display: flex;
    flex-flow: column wrap;
    align-items: stretch;
    align-content: flex-start;
    margin-bottom: var(--line-margin-bottom);
    margin-bottom: 0;
  }

  .titleContainer {
    --gw-dashboard-title-wrapper-width: 100%;
    width: var(--gw-dashboard-title-wrapper-width);
  }

  .startNewQuote {
    flex: 1;
  }

  .gwActivitiesTableWrapper {
    --gw-activities-table-wrapper-width: 100%;

    width: var(--gw-activities-table-wrapper-width);
    background: var(--GW-BACKGROUND-COLOR);
    border-radius: var(--GW-BORDER-RADIUS);
    box-shadow: var(--GW-SHADOW-2);
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
  }
}

.gwTitleStyle {
  padding-bottom: var(--GW-LAYOUT-3);
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}