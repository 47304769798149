.gwKnockOutContainer {
  --max-width: 90em;

  margin: 0 auto;
  max-width: var(--max-width);
  padding: 40px 0;
}

.gwKnockOutPage {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 70%;
  height: 100%;
  margin: 0 auto 5px;
  padding: 0;
}

.gwKnockOutIcon {
  --icon-container-size: 100px;
  --gw-knockout-component-icon-size: 3rem;

  border: 1px solid var(--GW-COLOR-SUCCESS);
  color: var(--GW-COLOR-SUCCESS);
  width: var(--icon-container-size);
  height: var(--icon-container-size);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--gw-knockout-component-icon-size);
  margin: auto;
}

h2.gwKnockOutTitle {
  font-weight: var(--GW-FONT-WEIGHT-LIGHT);
  margin-top: var(--GW-LAYOUT-8);
}

.gwKnockOutMoreInfo {
  margin-top: var(--GW-LAYOUT-8);
}

.gwKnockOutDescription {
  color: var(--GW-COLOR-WARNING);
  margin-top: var(--GW-LAYOUT-8);
}

.gwKnockOutButton {
  --button-width: 230px;

  width: var(--button-width);
  margin: var(--GW-LAYOUT-8) auto 0;
}
