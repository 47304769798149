:root {
	--gw-wc7-update-button-margin: 0 var(--GW-SPACING-3);
	--gw-wc7-button-width: 200px;
}
.expModActions {
	display: flex;
	justify-content: flex-end;
	margin-bottom: var(--GW-SPACING-3);
	.expModUpdate {
		margin: var(--gw-wc7-update-button-margin);
	}
}

.supplementryDisease{
	border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.addCoverages, .addScheduledItems {
	width: var(--gw-wc7-button-width);
}
