@font-face {
  font-family: 'Source Sans Variable';
  font-weight: 200 900;
  font-style: normal;
  font-stretch: normal;
  src: url('#{$source-sans-pro-root}/VAR/SourceSansVariable-Roman.ttf.woff2')
      format('woff2'),
    url('#{$source-sans-pro-root}/VAR/SourceSansVariable-Roman.ttf.woff')
      format('woff'),
    url('#{$source-sans-pro-root}/VAR/SourceSansVariable-Roman.ttf')
      format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Source Sans Variable';
  font-weight: 200 900;
  font-style: italic;
  font-stretch: normal;
  src: url('#{$source-sans-pro-root}/VAR/SourceSansVariable-Italic.ttf.woff2')
      format('woff2'),
    url('#{$source-sans-pro-root}/VAR/SourceSansVariable-Italic.ttf.woff')
      format('woff'),
    url('#{$source-sans-pro-root}/VAR/SourceSansVariable-Italic.ttf')
      format('truetype');
  font-display: swap;
}
