.icon {
  color: var(--GW-SEPARATOR-COLOR);
  position: absolute;
  top: var(--GW-SPACING-2);
  right: var(--GW-SPACING-2);
}

.clearIcon {
  top: 0;
  right: 0;
}

.zeroPadding {
  padding: 0;
}

.gwTypeaheadResults{
  --gw-typeahead-border: 1px;
  list-style: none;
  padding: 0;
  background-color: var(--GW-BACKGROUND-COLOR);
  border: var(--gw-typeahead-border) solid var(--GW-BORDER-COLOR);
}

.gwTypeaheadMatch {
  padding: var(--GW-SPACING-2);

  &:hover {
    background-color: var(--GW-DROPDOWN-OPTION-BACKGROUND-COLOR-HOVER);
  }
  &:active {
    background-color: var(--GW-DROPDOWN-OPTION-BACKGROUND-COLOR-ACTIVE);
  }
}
