

:root {
  --gw-analytics-img-min-height: 550px;
  --gw-analytics-img-height: 728px;
}
.analytics {
  .gwRemoveInputLabel {
    grid-template-columns: 0fr auto;
  }

  .gwRowLayout {
    margin-bottom: var(--GW-LAYOUT-6);
  }

  .gwProducerCodeDivider {
    height: calc(0.6 * var(--GW-HEADER-HEIGHT-SM));
    border-left: solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH);
  }

  .gwGatewayReportFrame {
    height: var(--gw-analytics-img-height);
    width: 100%;
    min-height: var(--gw-analytics-img-min-height);
  }

  .gwAnalyticsPageTileContainer {
    padding-top: var(--GW-LAYOUT-3);
  }
}
