
@import "~@jutro/theme/assets/sass/helpers";
:root{
  --gw-timeline-definition-min-width: 190px;
  --gw-timeline-definition-term-min-width: 112px;
  --gw-timeline-definition-width: 50%;
}

.gwTimelineDefinition {
  margin-bottom: var(--GW-LAYOUT-3);
  min-width: var(--gw-timeline-definition-width);
  width: var(--gw-timeline-definition-width);

  &__term {
    color: var(--GW-TEXT-COLOR-2);
    padding-right: var(--GW-LAYOUT-3);
  }

  &__definition {
    color: var(--GW-TEXT-COLOR-1-ACTIVE);
    width: 100%;
  }
}

.gwTimelineCurrencyStyle {
  [class*='CurrencyField'] {
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
  }
}

@include gw-breakpoint-only(tablet) {
  .gwTimelineDefinition {
    margin-bottom: var(--GW-LAYOUT-5);
    flex-direction: column;
    margin-right: var(--GW-LAYOUT-5);

    &__term {
      padding-bottom: var(--GW-LAYOUT-2);
    }
  }
}
