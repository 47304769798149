@import "~@jutro/theme/assets/sass/helpers";

:root {
    --gw-transaction-drop-down-width: 80px;
    --gw-display-no-message-margin-top: 8px;
    --gw-display-no-message-margin-left: 6px;
    --gwColumnLayout-margin-left: 10px;
    --gwColumnLayout-margin-right: 10px;
}

.openTransaction {
    .gwPageHeader {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    }

    .gwPageHeaderRightPanel {
        display: flex;
        width: 100%;
        justify-content: space-between;
    }

    .gwRowLayout {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        align-content: flex-start;
    }

    .iconMargin {
        --gw-margin-top: 6px;
        --gw-margin-right: 10px;
        margin-top: var(--gw-margin-top);
        margin-right: var(--gw-margin-right);
    }

    .gwRowLayoutItem {
        display: flex;
    }
    .gwColumnLayout{
        --column-layout-width: 224px;
        margin-right: var(--gwColumnLayout-margin-right);
        margin-left: var(--gwColumnLayout-margin-left);
        width: var(--column-layout-width);
        @include gw-breakpoint-down(tablet) {
          --column-layout-width: 180px;
        }
    }
    .gwFilterLayout {
        width: var(--gw-transaction-drop-down-width);
    }

    .gwOpenTransactionContainer {
        padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
    }
}

.searchInputSize {
    --search-input-width: 224px;
    width: var(--search-input-width);
    @include gw-breakpoint-down(tablet) {
      --search-input-width: 180px;
    }
}

.gwTitleStyle {
    padding: var(--GW-LAYOUT-3) 0;
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}