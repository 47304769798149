@import "~@jutro/theme/assets/sass/helpers";

.sideBar {
    @include gw-breakpoint-down(phonewide) {
        display: none;
    }
}

.gwWizard {
    --gw-wizard-min-height: 600px;
    display: inline-block;
    height: 100%;
    width: 100%;
    min-height: var(--gw-wizard-min-height);
    text-align: center;
    transform: translateX(0);
    transition: 0.5s all cubic-bezier(0.32, 1.25, 0.375, 1.15);
}

.wizardSidebar{
    --wizardSidebar-width: 23%;
    --wizardSidebar-margin-right: .4%;

    display: inline-block;
    width: var(--wizardSidebar-width);
    vertical-align: top;
    margin-right: var(--wizardSidebar-margin-right);
    text-align: center;
}

.wizardMain {
    --wizardMain-width: 75%;

    text-align: left;
    display: inline-block;
    width: var(--wizardMain-width);
    vertical-align: top;
}

.wizardSidebarSteps{
    text-align: left;
    background-color: var(--GW-BACKGROUND-COLOR);
    border-radius: var(--GW-BORDER-RADIUS);
}

.gwsteps {
    --gwsteps-line-height: var(--GW-LINE-HEIGHT-H5);

    line-height: var(--gwsteps-line-height);
}

.ulStyle {
    list-style: none;
    text-align: left;
    margin: var(--GW-LAYOUT-8);
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.wizardWrapper{
    padding-top: var(--GW-LAYOUT-4);
    padding-bottom: var(--GW-LAYOUT-1);
    text-align: left;
    background-color: var(--GW-BACKGROUND-COLOR);
    border-radius: var(--GW-BORDER-RADIUS);
    box-shadow: var(--GW-SHADOW-2);
}

.searchTopicInputField {
    margin-top: var(--GW-SPACING-5);
}

.gwFaqSection {
    padding-top: var(--GW-SPACING-5);
}

.faqSectionContainer {
    padding-bottom: var(--GW-LAYOUT-12);

  .faqnoresult {
    text-align: center;
    padding-top: var(--GW-SPACING-10);
  }
}

.wizardLi {
    display: block;
    padding: var(--GW-SPACING-3) var(--GW-SPACING-5) var(--GW-SPACING-3);
    cursor: pointer;

    a {
        cursor: pointer;
        color: var(--GW-LINK-COLOR);
        text-decoration: none;
    }

    a:hover {
        color: var(--GW-LINK-COLOR-HOVER);
        text-decoration: underline;
    }
}

.wizardLiDisabled {
    cursor: default;

    a {
        color: var(--GW-SEPARATOR-COLOR);
        text-decoration: none;
        pointer-events: none;
    }
}

.wizardLink {
    --wizardLink-width: 100%;

    text-decoration:none;
    width:var(--wizardLink-width);
}

.sideBarTitle {
    padding: 0 var(--GW-LAYOUT-3);
    margin-bottom: var(--GW-LAYOUT-3);
    margin-top: var(--GW-SPACING-3);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
    display: block;
}
.gwSectionInactive{
        pointer-events:none;
        color: var(--GW-SEPARATOR-COLOR) !important;
}
.gwSectionActive {
    background-color:var(--GW-COLOR-SUCCESS-CONTRAST);
}

.gwPageSectionTitle {
    padding-bottom: var(--GW-SPACING-3);
    margin-left: var(--GW-SPACING-6);
    margin-bottom: var(--GW-SPACING-10);
}
