@import "~@jutro/theme/assets/sass/helpers";

.paymentListTableCell {
  @include gw-breakpoint-down(tablet) {
    // FIXME: Remove !important when Jutro allow us to overwrite existing <td> styles
    padding: var(--GW-LAYOUT-1) !important; // Workaround using !important will work
  }
}

.paymentListTable {
  @include gw-breakpoint-down(tablet) {
    padding: var(--GW-LAYOUT-1) 0 var(--GW-LAYOUT-1) 0;

    th {
      padding: var(--GW-LAYOUT-1);
    }
  }
}
