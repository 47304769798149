:root {
    --gw-document-item-padding: 15px;
    --gw-document-item-link-margin-bottom: 5px;
    --gw-upload-document-grid-item-width: 385px;
    --gw-upload-document-grid-item-margin: 10px;
    --gw-upload-document-grid-delete-margin-bottom: 18px;
    --gw-upload-document-grid-delete-width: 40px;
    --gw-upload-document-grid-delete-height: 40px;
    --gw-upload-document-grid-delete-icon-border-radius: 20px;
    --gw-witness-dropdown-menu-padding: 10px;
    --gw-witness-table-input-disabled-height: 37px;
    --gw-witness-upload-document-box-width: 48px;
    --gw-involvement-injured-dropdown-width: 120px;
}

.additionalInfoContainer {
    .uploadDocumentSection {
        margin-top: var(--GW-SPACING-5);
    }

    .upload-document-grid {
        margin: var(--GW-LAYOUT-SPACING-5);
    }

    .upload-document-items {
        display: flex;
        flex-wrap: wrap;
    }
    .involvmentInjuredDropDown {
        width: var(--gw-involvement-injured-dropdown-width);
    }

    .upload-document-grid-item {
        border: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
        padding: var(--gw-document-item-padding);
        width: var(--gw-upload-document-grid-item-width);
        margin: var(--gw-upload-document-grid-item-margin);

        .document-name-link {
            margin-bottom: var(--gw-document-item-link-margin-bottom);
            text-decoration:none;
            justify-self: left;
        }
    }

    .addPersonDropDown {
        font-size: var(--GW-FONT-SIZE);

        >div {
            border: var(--GW-BORDER-WIDTH) solid var(--GW-BACKGROUND-COLOR-BODY);
            padding: var(--gw-witness-dropdown-menu-padding) 0;
            --dropdownmenu-max-width: auto;
            max-width: var(--dropdownmenu-max-width);
        }

        a {
            font-size: var(--GW-FONT-SIZE);

            &:hover {
                background-color: var(--GW-BACKGROUND-COLOR-BODY);
            }
        }
    }

    .tableColumn{
        input[disabled] {
            height: var(--gw-witness-table-input-disabled-height);
            width:100%;
            border: solid var(--input-border-width) var(--input-border-color);
            padding: var(--input-padding-y) var(--input-padding-x);
        }
    }
    .uploadDocumentBox{
        display: flex;
        align-items: left;
        width:100%;
        justify-content: left;
        div[class*='uploadDocumentSection'] {
            width: calc(100% - var(--gw-witness-upload-document-box-width));
            text-align: left;
        }
   }
   .witnessTable {
        --gw-witness-table-padding-left-right: 1rem;
        padding: var(--table-padding-x) var(--gw-witness-table-padding-left-right);
    }

    .witnessPartyTable {
        --table-padding-x: 0;
    }
}
