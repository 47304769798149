@import '~@jutro/theme/assets/sass/helpers';

.retrieveQuoteButtonContainer {
  @include gw-breakpoint-down(tablet) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  display: flex;
  flex-direction: column;
}

.retrieveQuoteContinueButton {
  margin-top: var(--GW-SPACING);
  margin-bottom: var(--GW-SPACING);
}
