.gwCancelPolicyTitleContainer {
	border-bottom: solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH);
	margin-bottom: var(--GW-LAYOUT-8);
}
.gwPolicyCancellationContainer {
	padding-top: var(--GW-LAYOUT-8);
}
.cancellationMessage {
	[class*=FieldMessage] {
		display: none;
	}
}
.gwEffectiveDateErrorMessage {
    color: var(--GW-COLOR-ERROR);
}
.cancelationInlineNotification {
	i[class*=mi-info]{
		color: var(--GW-BRAND-COLOR-2);
	}
	background-color: var(--GW-HIGHLIGHT-COLOR-1);
	color: var(--GW-TEXT-COLOR-1);
	border: solid 1px var(--GW-SEPARATOR-COLOR);
}
