.logInViewContainer {
    --gw-login-container-padding-top: var(--GW-SPACING-6);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: var(--gw-login-container-padding-top);
    border-top: 1px solid var(--GW-BACKGROUND-COLOR-ELEMENT);
    flex-grow: 1;
}
