@import "~@jutro/theme/assets/sass/helpers";

.clause {
    --clause-background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
    --clause-padding: var(--GW-LAYOUT-5);

    @include gw-breakpoint-down(tablet) {
        padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-1) var(--GW-LAYOUT-6) var(--GW-LAYOUT-1);
    }

    @include gw-breakpoint-down(phonewide) {
        --clause-padding: var(--GW-LAYOUT-4);
    }

    background-color: var(--clause-background-color);
    padding: var(--clause-padding);
    text-align: left;
}

.clauseNoPadding {
    padding: 0;
}

.clauseAndTermSpacing {
    --gw-clause-component-spacing: var(--GW-LAYOUT-3);
    margin-bottom: var(--gw-clause-component-spacing);
}

// Not Editable Styles
.readOnlyGrid {
    --clause-margin: var(--GW-LAYOUT-4);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: 'left right';
    margin: var(--clause-margin) var(--clause-margin) 0 var(--clause-margin);

    &:not(:last-child) {
        --read-only-clause-margin: var(--GW-LAYOUT-3);

        margin-bottom: var(--read-only-clause-margin);
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    }
}
.clauseContainer {
    &:not(:first-child) {
        margin-top: var(--GW-LAYOUT-2);
    }
}
.readOnlyGridSlotLeft {
    grid-area: left;
}

.readOnlyGridSlotRight {
    grid-area: right;
}

.readOnlyGridAmount {
    --read-only-clause-amount-margin: var(--GW-LAYOUT-1);
    margin-left: var(--read-only-clause-amount-margin);
}

.clauseTermReadonly {
    grid-template-columns: 3fr 1fr;
}
