@import "~@jutro/theme/assets/sass/helpers";

.gwMediaSidebarTextContainer {
  background-color: var(--GW-COLOR-SUCCESS-CONTRAST);
  margin-bottom: var(--GW-LAYOUT-2);
  padding: var(--GW-LAYOUT-1);

  @include gw-breakpoint-down(tablet) {
    display: none;
  }
}
