@import "~@jutro/theme/assets/sass/helpers";

:root {
  --gw-gateway-page-height: 400px;
  --gw-header-page-bottom-line: 2px;
  --gw-header-column-margin-right: 10px;
  --gw-header-column-margin-left: 10px;
  --gw-display-nomessage-margin-top: 8px;
  --gw-search-column-layout-width: 37.5%;
  --gw-display-nomessage-margin-left: 6px;
}

.linkAccount {
    a {
        text-decoration: none;
        color: var(--GW-LINK-COLOR);
    }
}

.gwPageHeaderRightPanel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.gwRowLayout {
  display: flex;
  align-items: stretch;
  align-content: flex-start;
}

.gwColumnLayout {
  --column-layout-width: 224px;
  margin-left: var(--gw-header-column-margin-left);
  width: var(--column-layout-width);
  @include gw-breakpoint-down(tablet) {
    --column-layout-width: 180px;
  }
}

.gwRowSearchLayout {
  width: var(--gw-search-column-layout-width);
  margin-left: var(--gw-header-column-margin-left);
}

.currencyContainer {
  div[class*="fieldComponent"] {
    padding-bottom: 0;
  }
}

.gwOpenQuoteContainer {
  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
}
.searchInputSize {
  --search-input-width: 224px;
  width: var(--search-input-width);
  @include gw-breakpoint-down(tablet) {
    --search-input-width: 180px;
  }
}

.gwTitleStyle {
  padding: var(--GW-LAYOUT-3) 0;
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.gwQuotesListTableContainer {
  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
}

:global(.themeRoot h2) {
  margin-bottom: 0;
}