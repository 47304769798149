.gwTimelineCoverageDiff {
  margin-bottom: var(--GW-LAYOUT-3);
  width: 100%;

  &__coverage {
    width: 100%;
    padding-bottom: var(--GW-SPACING-4);
    margin-bottom: var(--GW-SPACING-4);
  }

  &__heading {
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    color: var(--GW-TEXT-COLOR-1);
    padding-bottom: var(--GW-LAYOUT-12);
  }
}

.gwTimelineCoverageArray {
  width: 100%;
}

