.gwRowLayout {
    margin-bottom: var(--GW-LAYOUT-6);
}

.gwAccountPageTileContainer {
    padding-top: var(--GW-LAYOUT-3);
}

.gwProducerCodeDivider {
    height: calc(0.6 * var(--GW-HEADER-HEIGHT-SM));
    border-left: solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH);
}

.gwAccountsListTableContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
}

.gwTitleStyle {
    padding-bottom: var(--GW-LAYOUT-3);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
  }