
@import "~@jutro/theme/assets/sass/helpers";
:root {
	--gw-timleine-event-header-line-icon-size: 22px;
	--gw-timleine-event-header-line-border-radius: 50%;
	--gw-timleine-event-header-line-width-height: 22px;
	--gw-timleine-event-header-line-icon-left-right: -51px;
}
.gwTimelineEventHeader {
	--gw-common-timeline-color: var(--GW-SEPARATOR-COLOR);
    --gw-common-timeline-color-hover: var(--GW-SEPARATOR-COLOR);
	--gw-timeline-event-header-padding: 0px var(--GW-LAYOUT-3);
	cursor: pointer;
	padding: var(--gw-timeline-event-header-padding);
	display: flex;
	width: 100%;
	align-items: flex-start;
	color: var(--GW-COLOR-SUCCESS-CONTRAST);
	outline-width: 0;
	border: transparent;
	background: var(--GW-BACKGROUND-COLOR-BODY);
	&:hover {
		background: var(--GW-BACKGROUND-COLOR-BODY);

		&::before {
			border-right-color: var(--GW-SEPARATOR-COLOR);
		}
	}
	&__title {
		display: flex;
		flex-direction: column;
		padding: var(--GW-LAYOUT-4);
	}

	&__date {
		text-align: left;
	}

	&__lineIcon {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: var(--gw-timleine-event-header-line-border-radius);
		flex-shrink: 0;
		align-self: center;
		font-size: var(--GW-ICON-FONT-SIZE-2);
		color: var(--GW-SEPARATOR-COLOR);
		background: var(--GW-COLOR-SUCCESS-CONTRAST);
		padding: var(--GW-SPACING-3);
	}

	&__collapse {
		display: block;
		padding: var(--GW-LAYOUT-4) var(--GW-LAYOUT-1);
		margin-left: auto;
		font-size: var(--GW-ICON-FONT-SIZE-3);
	}

	&--open {
		border-bottom-left-radius: 0;
		border-bottom-right-radius: 0;
	}

	&--personalauto {
		background: var(--GW-BACKGROUND-COLOR-BODY);
		&::before {
			border-right-color: var(--GW-SEPARATOR-COLOR);
		}
		&:hover {
			background: var(--GW-BACKGROUND-COLOR-BODY);
			&::before {
				border-right-color: var(--GW-SEPARATOR-COLOR);
			}
		}
		&__lineIcon {
			color: var(--GW-SEPARATOR-COLOR);
		}
	}

	&--homeowners {
		background: var(--GW-BACKGROUND-COLOR-BODY);
		&::before {
			border-right-color: var(--GW-SEPARATOR-COLOR);
		}
		&:hover {
			background: var(--GW-BACKGROUND-COLOR-BODY);
			&::before {
				border-right-color: var(--GW-SEPARATOR-COLOR);
			}
		}
		&__lineIcon {
			color: var(--GW-SEPARATOR-COLOR);
		}
	}
}

.gwTimelineEventHeaderOdd {
	flex-direction: row-reverse;
	.gwTimelineEventHeader {
		&__title {
			align-items: flex-end;
		}
		&__collapse {
			margin-left: 0;
			margin-right: auto;
		}
	}
}

.gwTimelineEventIcon {
	width: var(--gw-timleine-event-header-line-icon-size);
	height: var(--gw-timleine-event-header-line-icon-size);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: var(--gw-timleine-event-header-line-border-radius);
	flex-shrink: 0;
	background: var(--GW-ACCENT-COLOR);
	font-size: var(--GW-ICON-FONT-SIZE-2);
	color: var(--GW-TEXT-COLOR-3);
	top: var(--GW-LAYOUT-2);
	position: absolute;
	left: var(--gw-timleine-event-header-line-icon-left-right);
	z-index: 1;
}

.gwTimelineEventIcon {
	&_odd {
		right: var(--gw-timleine-event-header-line-icon-left-right);
		left: auto;
	}
}

.gwTimelineEventIcon {
	&_oddOpen {
		transform: scale(1.15385);
	}
}

.gwTimelineEventIcon_inner {
	--gw-common-timeline-inner-icon-color: var(--GW-ACCENT-COLOR);
    --gw-common-timeline-inner-icon-color-hover: var(--GW-ACCENT-COLOR);
	width: var(--gw-timleine-event-header-line-width-height);
	height: var(--gw-timleine-event-header-line-width-height);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: var(--gw-timleine-event-header-line-border-radius);
	flex-shrink: 0;
	background: var(--gw-common-timeline-inner-icon-color);
	&--personalauto {
		background: var(--gw-common-timeline-inner-icon-color);
		&:hover {
			background: var(--gw-common-timeline-inner-icon-color-hover);
		}
	}
	&--homeowners {
		background: var(--gw-common-timeline-inner-icon-color);
		&:hover {
			background: var(--gw-common-timeline-inner-icon-color-hover);
		}
	}
}
@include gw-breakpoint-only(tablet) {
	.gwTimelineEventIcon {
	--gw-timleine-event-header-line-icon-left: -66px;
	left: var(--gw-timleine-event-header-line-icon-left);
		&_odd {
		left: var(--gw-timleine-event-header-line-icon-left);
	}
}

	.gwTimelineEventHeaderOdd {
		flex-direction: row;
		.gwTimelineEventHeader {
			&__title {
				align-items: flex-start;
			}
			&__collapse {
				margin-right: 0;
				margin-left: auto;
			}
		}
	}
}