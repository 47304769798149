.makePayment {
  --gw-padding-right: 10px;

  .accountContainer {
    text-align: right;
  }

  .manualPaymentContainer {
    padding-bottom: var(--GW-LAYOUT-8);
  }
  .enterYourPaymentAmountInput {
    --gw-enter-payment-amount-max-width: 20rem;
    max-width: var(--gw-enter-payment-amount-max-width);
  }

  .totalPaymentSelectedClass {
    --gw-total-payment-selected-max-width: 378px;
    max-width: var(--gw-total-payment-selected-max-width);
    flex: 1 1 auto;
    line-height: var(--GW-LINE-HEIGHT-SUBTEXT);
    padding-bottom: var(--gw-summary-container-padding-bottom);
    min-width: var(--gw-summary-container-width);
  }

  .cancelLinkClass {
    margin: var(--GW-SPACING-10) auto 0;
  }

  .removeLinkStyle {
    text-decoration: none;
    color: var(--GW-LINK-COLOR);
  }

  .totalPaymentSelectedContainer {
    padding-bottom: var(--GW-LAYOUT-4);
  }

  .tabBillingHeaderSep {
    margin: var(--GW-LAYOUT-4) 0;
  }

  .totalPaymentContainer {
    display: flex;
    .totalPaymentSelectedLabel {
      padding-right: var(--gw-padding-right);
    }
  }

  .invoiceGridContainer {
    display: flex;
  }
  .columnLeft {
    padding-right: var(--gw-padding-right);
  }
  .notificationMessage {
    margin: var(--GW-SPACING-10) auto 0;
  }
  .invoiceMsgBlock {
    margin-top: var(--GW-SPACING-10);
  }
}

.currencyAmount {
  padding-bottom: 0;
}

.amountToPayWarningMessage {
  text-align: center;
  color: red;
}


.acceptTnC {
  margin-left: auto;
  margin-top: var(--GW-SPACING-6);
  margin-right: var(--GW-SPACING-1);
}

.paymentButtonStyle {
  margin-left: var(--GW-LAYOUT-4);
}

.termsCheckbox {
  display: flex;
  align-items: flex-end;
  margin-bottom: var(--GW-SPACING-4);
  .link {
      text-decoration: underline;
  }
}

.paymentButtonAlignment {
  text-align: right;
}

.amountToPayWarningMessage {
  text-align: left;
  color: red;
}

.messageSeparator {
  padding-top: var(--GW-SPACING-2);
}

.convertedWarningMargin {
  margin-bottom: var(--GW-SPACING-4);
}