:root{
  --gw-quoteContainer-padding:10px 0 0;
  --gw-quoteContainer-icon-border-radius:50%;
  --gw-quoteContainer-icon-width:33px;
  --gw-quoteContainer-icon-height:33px;
  --gw-quoteContainer-inner-width:50%;
  --gw-quoteContainer-inner-right-width:45%;
  --gw-quoteContainer-quote-total-width:100%;
}

.gwQuoteDetailsContainerBox {
  display: flex;
  padding: var(--gw-quoteContainer-padding);
  justify-content: space-between;
  align-items: stretch;
}

.iconClass {
 border-radius: var(--gw-quoteContainer-icon-border-radius);
 width: var( --gw-quoteContainer-icon-width);
 height: var(  --gw-quoteContainer-icon-height);
 background-color: var(--GW-BORDER-COLOR);
 display: flex;
 justify-content: center;
 align-items: center;
 }

.gwInnerQuoteBoxLeft {
  width: var(--gw-quoteContainer-inner-width);
}

.gwInnerQuoteBoxRight {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: var( --gw-quoteContainer-inner-right-width);
}

.gwQuoteTotalHR {
  border-top: 1px solid var(--GW-SEPARATOR-COLOR);
  margin: var(--GW-SPACING-3) 0;
  width: var( --gw-quoteContainer-quote-total-width);
  border-bottom: none;
}

.startDate {
  width: auto;
  padding: 0;
  align-items: baseline;
}

