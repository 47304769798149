:root {
    --gw-advanced-filter-width: 200px;
    --gw-advanced-filter-margin-right: 20px;
    --gw-filter-section-title-padding: 5px;
    --gw-filter-option-padding: 10px;
    --gw-filter-section-radio-padding-left: 10px;
    --gw-filter-option-margin-left: 10px;
    --gw-filter-sub-container-margin-left: 9%;
    --gw-filter-sub-container-padding-bottom: 10px;
}
.gw_advanced_filter {
    flex: 0 0 auto;
    margin-right: var(--gw-advanced-filter-margin-right);
    width: var(--gw-advanced-filter-width);
}
.gw_filter_section {
    background-color: var(--GW-TEXT-COLOR-3);
    flex-direction: column;
    width: var(--gw-advanced-filter-width);
}
.gw_filter_section_title {
    background-color: var(--GW-LINK-COLOR-HOVER);
    padding: var(--gw-filter-section-title-padding);
    color: var(--GW-TEXT-COLOR-3);
    text-transform: uppercase;
}
.gw_filter_option {
    flex-direction: column;
    padding: var(--gw-filter-option-padding);
}
.gw_filter_sub_option_container {
    margin-top: 0;
    margin-left: var(--gw-filter-sub-container-margin-left);
    border-left: solid thin var(--GW-SEPARATOR-COLOR);
}
.gw_filter_sub_option_container:last-child {
    padding-bottom: var(--gw-filter-sub-container-padding-bottom);
}
.gw_sub_option_title {
    color: var(--GW-LINK-COLOR-HOVER);
    text-transform: uppercase;
    margin-left: var(--gw-filter-option-margin-left);
}
.gwAdvancedFilterOptionsLabel {
    width: auto;
}
.gw_filter_section {
    div[class*='jut__RadioButton__radioButton'] {
        padding-left: var(--gw-filter-section-radio-padding-left);
    }
}
