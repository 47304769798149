

:root {
    --gw-policy-info-advanced-table-width: 80%;
    --gw-policy-info-quick-table-width: 100%;
}

.gwProducerCodeDivider {
    --gw-producer-code-divider-height: 30px;

    height: var(--gw-producer-code-divider-height);
    border-left: solid var(--GW-BORDER-COLOR) var(--GW-BORDER-WIDTH);
}

.gwPolicyInfoAdvancedTable {
    width: var(--gw-policy-info-advanced-table-width);
}

.gwPolicyInfoQuickTable {
    width: var(--gw-policy-info-quick-table-width);
}

.policyProductHeaderSection {
    .policyIcon {
        border-radius: 50%;
        background-color: var(--GW-TAB-BAR-HEADING-BORDER-COLOR-ACTIVE);
        color: var(--GW-TEXT-COLOR-3);
        padding: var(--GW-SPACING-3);
    }
}

.gwPoliciesContainerSection {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
}

.gwTitleStyle {
    padding-bottom: var(--GW-LAYOUT-3);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
  }

.gwPolicyPageTileContainer {
    padding-top: var(--GW-LAYOUT-2);
}

.gwRowLayout {
    .policyProductName {
        margin-bottom: 0;
        margin-right: var(--GW-LAYOUT-4);
        margin-left: 0;
    }
    padding-top: var(--GW-LAYOUT-3);
}