.menuBar {
    background: var(--GW-COLOR-INFO-CONTRAST);
    box-shadow: var(--GW-SHADOW-2);
    display: flex;
    height: calc(2 * var(--GW-SPACING-6));
    padding: 0 var(--GW-SPACING-6);
    position: relative;
    a {
      color: var(--GW-TEXT-COLOR-1);
      padding: var(--GW-SPACING-3) var(--GW-SPACING-4);
      text-decoration: none;
      &:hover {
        background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
        color: var(--GW-TEXT-COLOR-1);
      }
    }
    a.activeNavItem {
      background-color: var(--GW-HIGHLIGHT-COLOR-2);
      border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-ACCENT-COLOR);
    }
    .navIcon {
      vertical-align: text-bottom;
    }
  }

  @media print {
    .menuBar {
      display: none;
    }
  }
