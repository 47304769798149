.gwContactModule {
  display: flex;
  flex-direction: row;
  background-color: var(--GW-BACKGROUND-COLOR);
  padding: var(--GW-SPACING-3) 0 var(--GW-SPACING-2) var(--GW-SPACING-3);

  .gwContactInformationContainer {
    width: 100%;
    color: var(--GW-TEXT-COLOR-2);
    word-break: break-word;
  }

  .gwImageContainer {
    --gw-image-container-width: 50%;
    --gw-image-container-height: 100%;

    width: var(--gw-image-container-width);
    height: var(--gw-image-container-height);

    img {
      max-width: fit-content;
    }
  }
}
