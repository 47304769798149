:root {
  --gw-divider-margin: var(--GW-SPACING-5);
  --gw-divider-padding: var(--GW-SPACING-5);
  --gw-billing-input-field-thin-height: var(--GW-SPACING-5);
}

.gwProducerCodeDivider {
  display: inline-block;
  border-left: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR);
  padding-right: var(--gw-divider-padding);
  margin-left: var(--gw-divider-margin);
  margin-bottom: var(--gw-divider-margin);
  margin-top: var(--gw-divider-margin);
}

.iconStyle {
  --gw-icon-top-padding: var(--GW-SPACING);
  padding-top: var(--gw-icon-top-padding);
}

.wc7PolicyPeriodDateLabelClass {
  text-align: center;
}

.wc7PolicyPeriodDateContainerStyle {
  align-items: center;
  align-content: center;
}

.wc7PolicyPeriodDate {
  div[class*='dateRangeContainer'] {
    display: inline-flex;
  }
  div[class*='startDateValue'] {
    --gw-start-date-range-width: 100%
  }
  div[class*='endDateValue'] {
    --gw-end-date-range-width: 100%
  }
}

.totalCostStyle {
  div[class*='currency'] {
    font-size: var(--GW-FONT-SIZE-H4);
    font-weight: var(--GW-FONT-WEIGHT-BOLD);
    color: var(--GW-COLOR-WARNING-CONTRAST);
  }
}

.totalDue {
  border-top: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
}

.gridBorder {
  border: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
  padding: var(--GW-SPACING-4)
}

.periodContainer {
  text-align: center;
  width: 100%;
}
