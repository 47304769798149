@import "~@jutro/theme/assets/sass/helpers";

:root {
	--gw-policy-details-summary-section-top: 1;
	--gw-policy-details-summary-section-bottom: 2;
	--gw-policy-details-summary-section-margin-bottom: 2rem;
	--gw-policy-columnHorizontal-width: 300px;
	--gw-summary-details-icon-margin-right: 0.5rem;
	--gw-summary-details-cost-value-margin-left: 1rem;
}

@mixin summaryDetails {
	background: var(--GW-BACKGROUND-COLOR);
    border-radius: var(--GW-BORDER-RADIUS);
    box-shadow: var(--GW-SHADOW-2);
    padding: var(--GW-SPACING-6) var(--GW-SPACING-6) var(--GW-SPACING-8);
}
.columnHorizontal {
	width: var(--gw-policy-columnHorizontal-width);
}
.summaryDetailGrid {
	margin-bottom: var(--GW-SPACING-6);
}
.summaryDetailBottom {
	@include summaryDetails;
	@include gw-breakpoint-down(tablet) {
		grid-row: var(--gw-policy-details-summary-section-bottom);
    }
}
.summaryDetailTop {
	@include summaryDetails;
    @include gw-breakpoint-down(tablet) {
		grid-row: var(--gw-policy-details-summary-section-top);
		margin-bottom: var(--gw-policy-details-summary-section-margin-bottom);
    }
}
.currentPolicyText {
	flex: 1 1 100%;
}
.policyLinkStyle  {
	margin-left: 0;
}
hr.costSeparator {
	margin: var(--GW-SPACING-2) 0;
}
.newPremiumContainer {
	color: var(--GW-TEXT-COLOR-2);
	div[class*='controlReadOnly'] {
		color: var(--GW-TEXT-COLOR-2)
	}
}
.billingCurrencyRightAlign {
	text-align: right;
	display: inline-flex;
}
.changeInCostContainer {
	display: flex;
	flex-direction: column;
	vertical-align: super;
	font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
	text-align: left;
}
.changeInCostTextPercentage {
	background-color: transparent;
}
.changeInCostIcon {
	font-size: var(--GW-ICON-FONT-SIZE-3);
    margin-right: var(--gw-summary-details-icon-margin-right);
}
.changeInCostDecrease {
	color: var(--GW-COLOR-INFO);
}
.changeInCostIncrease {
	color: var(--GW-COLOR-ERROR);
}
.newPermiumValue {
	margin-left: var(--gw-summary-details-cost-value-margin-left);
}
.newPremiumContainer > span {
	display: flex;
}
