@import "~@jutro/theme/assets/sass/helpers";
:root {
  --pageHeader-application-header-search-padding: 0 1.5rem;
  --pageHeader-application-logo-margin-top-bottom: 50%;
  --pageHeader-application-logo-width: 186px;
  --pageHeader-application-logo-height: 45px;
  --pageHeader-application-logoPortrait-width: 30px;
  --pageHeader-application-logoPortrait-height: 30px;
  --pageHeader-searchbox-width: 25rem;
  --pageHeader-searchbox-height: 2.5rem;
  --pageHeader-start-newquote-padding-left: 1rem;
}

.pageHeader {
  .applicationHeader {
    position: relative;
    height: calc(2 * var(--GW-SPACING-8));
    background-color: var(--GW-BRAND-COLOR-2);
    padding: 0 var(--GW-SPACING-6);
    border: none;

    .gwAppHeader {
      width: 100%;
    }
    .gwAuthStatusContainer {
      .faqLink,
      .preferenceLink,
      .logoutLink {
        cursor: pointer;
      }
    }
    .startNewQuote {
      margin-left: var(--pageHeader-start-newquote-padding-left);;
    }
  }

  .headerSearchBoxContainer {
    .headerSearchBox {
      color: var(--GW-TEXT-COLOR-3);
      background-color: var(--GW-HEADER-SEARCH-BACKGROUND-COLOR);
      border: none;
      font-size: var(--GW-FONT-SIZE-BODY);
      line-height: var(--GW-SPACING-6);
    }

    .headerSearchBox::placeholder {
      color: var(--GW-TEXT-COLOR-3);
    }

    i {
      color: var(--GW-TEXT-COLOR-3);
    }
  }

  @include gw-breakpoint-up(tablet) {
    .headerSearchBox {
      width: var(--pageHeader-searchbox-width);
      height: var(--pageHeader-searchbox-height);
    }

    .producerEngageContainer {
      padding-bottom: var(--gw-main-container-padding);

      >div {
        margin: 0;
      }
    }
  }

  @include gw-breakpoint-only(tablet) {
    --pageHeader-searchbox-width: 20rem;
    .gwHeaderLeft {
      .appLogoIcon {
        margin: var(--pageHeader-application-logo-margin-top-bottom) auto;
      }
    }

    .producerEngageContainer {
      padding-bottom: var(--gw-main-container-padding);

      >div {
        margin: 0;
      }
    }
  }
}

@media print {
  .faq {
    display: none;
  }
  .gwAuthStatus {
    display: none;
  }
}
