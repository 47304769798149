@import "~@jutro/theme/assets/sass/helpers";

.accountDetailsPrimaryPayerAddress {
    display: flex;
    flex-direction: column;
}

.accountbilling {
    .boldText {
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }

    .billingSummaryGridBorder {
        border-right: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
        padding-right: var(--GW-LAYOUT-6);
    }

    .billingSummarySeparator {
        --gw-billing-summary-separator-width: 95%;
        width: var(--gw-billing-summary-separator-width);
        margin-top: var(--GW-LAYOUT-3);
    }

    .regularText {
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    }

    .billingCurrencyRightAlign {
        display: grid;
        grid-template-columns: 3fr 1fr;
        width: 100%;
        text-align: right;
        padding-right: var(--GW-LAYOUT-12);
    }

    .billingPagePrimaryPayerAddressRow {
        margin: 0;
    }

    .billingSummaryGrid {
        padding: var(--GW-LAYOUT-3);
    }

    .payerContainer {
        margin-bottom: var(--GW-LAYOUT-6);
    }

    .producerCodesBlock {
        margin-bottom: var(--GW-SPACING-4);
    }

    .accountBillingIcon {
        font-size: var(--GW-ICON-FONT-SIZE-3);
        display: flex;
        align-items: center;
    }

    .accountBillingDelinquent {
        color: var(--GW-COLOR-WARNING);
    }

    .accountBillingGoodStanding {
        color: var(--GW-COLOR-SUCCESS);
    }

    .automaticPaymentsIcon {
        --automatic-payments-icon-border-radius: 100%;
        --automatic-payments-icon-margin-top: -0.5rem;
        --automatic-payments-icon-width-height: 40px;

        border: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
        padding: var(--GW-SPACING-4);
        border-radius: var(--automatic-payments-icon-border-radius);
        background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
        height: var(--automatic-payments-icon-width-height);
        width: var(--automatic-payments-icon-width-height);
        position: relative;
        align-items: center;
        justify-content: center;
        display: flex;
        margin-top: var(--automatic-payments-icon-margin-top);
    }

    .automaticPaymentsIconCheck {
        --automatic-payments-icon-check-border-radius: 50%;

        position: absolute;
        border: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
        background-color: var(--GW-COLOR-SUCCESS);
        color: var(--GW-TEXT-COLOR-3);
        border-radius: var(--automatic-payments-icon-check-border-radius);
        left: var(--GW-SPACING-5);
        top: var(--GW-SPACING-5);
    }

    .setupAutomaticPayment {
        margin-top: var(--GW-SPACING-6);
        margin-left: var(--GW-SPACING-3);
    }

    .chevronStyle {
        color: var(--GW-TEXT-COLOR-2);
    }

    .accordionTitle {
        background-color: transparent;
        color: var(--GW-TEXT-COLOR-2);
        cursor: pointer;
        margin-left: var(--GW-LAYOUT-12);
        margin-top: var(--GW-SPACING);
    }

    .paymentInstrumentSummary {
        --gw-payment-instrument-summary-grid-columns-tablet: 1fr;
        margin-top: var(--GW-LAYOUT-5);

        @include gw-breakpoint-down(phonewide) {
            grid-template-columns: var(--gw-payment-instrument-summary-grid-columns-tablet) var(--gw-payment-instrument-summary-grid-columns-tablet) !important;
        }
    }

    .makeAPayment {
        margin-top: var(--GW-SPACING-8);
    }

    .billingPageProducerCode,
    .billingPagePrimaryPayer,
    .billingPageTabsContainer {
        padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
    }

    .invoiceStreamTitle {
        padding: var(--GW-SPACING-5) 0;
    }

    .customerPaymentSummaryStyle {
        color: var(--GW-TEXT-COLOR-2);
    }

    .totalDueTitle {
        text-transform: uppercase;
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }

    .totalDueSeparator {
        margin: var(--GW-LAYOUT-3) 0;
    }

    .tabAutomaticPaymentsSetupMessageContainer {
        padding-right: var(--GW-LAYOUT-8);
    }

    .paymentSetupSection {
        margin-left: var(--GW-LAYOUT-5);
    }

    .paymentSetupPremiumLabel {
        text-align: right;
    }

    .tabAutomaticPaymentsScheduleDetails {
        padding: var(--GW-LAYOUT-3) 0;
    }

    .paymentScheduleMessageLabelDate {
        font-weight: var(--GW-FONT-WEIGHT-BOLD);
        padding-left: var(--GW-SPACING-1);
    }

    .detailedScheduleTable {
        margin-top: var(--GW-LAYOUT-5);
    }

    .paymentSetupFooter {
        text-align: right;
    }

    .cancelSetupPayment {
        --gw-cancel-setup-payment-margin-right: 0.25em;
        margin-right: var(--gw-cancel-setup-payment-margin-right);
    }

    .setupPaymentConfirmWrapper {
        padding-top: var(--GW-LAYOUT-8);
        padding-bottom: var(--GW-LAYOUT-10);
    }

    .setupPaymentConfirmContainer {
        border: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
        border-radius: var(--GW-BORDER-RADIUS);
        padding: var(--GW-LAYOUT-8);
        text-align: center;
    }

    .setupPaymentSuccessValue {
        display: block;
    }

    .setupPaymentSuccessPhone {
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        font-style: italic;
    }

    .billingNoData {
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
        margin-top: var(--GW-LAYOUT-10);
    }

    .currencyContainer {
      div[class*="fieldComponent"] {
        padding-bottom: 0;
      }
    }

    .headerSep {
        margin: var(--GW-LAYOUT-4) 0 0 0;
    }

    .gwTitleStyle {
        padding: var(--GW-LAYOUT-3);
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    }

    .gwPolicyOwnedListTableContainer {
        margin: var(--GW-LAYOUT-6) 0;
    }
    .gwPolicyUnOwnedListTableContainer {
        margin: var(--GW-LAYOUT-6) 0;
    }
    .tabBillingContent {
        margin-top: var(--GW-LAYOUT-6);
    }
    .totalDisputeWarning {
        margin-bottom: var(--GW-LAYOUT-4);
    }
}

.messageSeparator {
    padding-top: var(--GW-SPACING-2);
}

.convertedWarningMargin {
    margin-bottom: var(--GW-SPACING-4);
}

.wrapWarning {

    div:first-child {
        white-space: normal;
        text-overflow: clip !important;
        overflow: hidden !important;
        min-height: 70px;
    }
}