@import "~@jutro/theme/assets/sass/helpers";

.titleContainer {
	padding: var(--GW-SPACING-3) 0;
	border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	display: flex;
	justify-content: space-between;
	width: 100%;
}
.gwRowLayout {
	display: flex;
	align-items: stretch;
	align-content: flex-start;
}

.buttonFileUpload {
	margin-right: var(--GW-SPACING-5);
}
.trashLink {
	i {
		--link-icon-font-size: var(--GW-FONT-SIZE-H4);
	}
}

.documentTitleRight {
	.uploadbutton {
		padding-right: var(--GW-SPACING-6);
		min-width: auto;
	}
}

.searchInputSize {
	--gw-search-input-width-size: 14rem;
	width: var(--gw-search-input-width-size);
	@include gw-breakpoint-down(tablet) {
	--gw-search-input-tablet-width-size: 11rem;
	  width: var(--gw-search-input-tablet-width-size);
	}
}

.gwDocumentContainer {
	padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
}

.documentFileNameColumnLink {
	display: contents !important;
}