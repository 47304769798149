@import "~@jutro/theme/assets/sass/helpers";

:root {
	--gw-summary-container-padding-bottom: var(--GW-LAYOUT-5);
	--gw-summary-container-width: 150px;
	--gw-loader-wrapper-min-height: 4.4em;
	--gw-loader-wrapper-min-width: 1.2em;
	--gw-box-margin: 0 auto 5px;
	--gw-page-section-header-row-padding-top: 8px;
	--gw-emphasis-padding-bottom: 5px;
	--gw-billing-payer-address-height: 20px;
	--gw-producer-codelabel-margin-left: -10px;
	--gw-account-text-padding-left: 2rem;
	--gw-account-text-padding-right: 2rem;
	--gw-summary-container-fourthchild-width: 180px;
	--gw-summary-container-lastchild-width: 300px;
	--gw-header-row-margin-bottom: 10px;
}

.summary {
	.gwLoaderWrapperLarge {
		min-height: var(--gw-loader-wrapper-min-height);
		min-width: var(--gw-loader-wrapper-min-width);
	}
	.gwAccountSummaryWrapper {
		width: 100%;
		display: grid;
		grid-gap: var(--GW-LAYOUT-6);
		grid-template-columns: 1fr 1fr;

		@include gw-breakpoint-down(tablet) {
			grid-template-columns: 1fr;
		}
	}
	.accountDetails {
		flex: 1 1;
		background: var(--GW-BACKGROUND-COLOR);
		padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
		border-radius: var(--GW-BORDER-RADIUS);
		box-shadow: var(--GW-SHADOW-2);
	}
	.gwSummaryContainer {
		flex: 2 2;
		padding-bottom: var(--gw-summary-container-padding-bottom);
		min-width: var(--gw-summary-container-width);
	}
	.gwEditButton {
		width: fit-content;
		margin-top: var(--GW-LAYOUT-4);
	}
	.gwEmphasis {
		line-height: var(--GW-LINE-HEIGHT);
		color: var(--GW-TEXT-COLOR-2);
		margin: 0;

		&:after {
			content: '\003a';
			padding-right: var(--gw-emphasis-padding-bottom);
		}
	}
	.gwPageSectionHeaderRow {
		display: flex;
		justify-content: flex-start;
		align-items: baseline;
		border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	}

	.gwPageSectionHeaderRowNewQuoteSection {
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
		margin-bottom: var(--GW-SPACING-3);
	}

	.newQuoteButton {
		background-color: var(--GW-BACKGROUND-COLOR);
		box-shadow: var(--GW-SHADOW-3);
	}
	.gwPageSectionHeaderRow a {
		text-decoration: none;
		cursor: pointer;
		color: var(--GW-LINK-COLOR);
	}

	.accountDetailBold {
		font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
		margin: 0;
	}

	.iconMargin {
		margin-top: var(--gw-iconMargin-margin-top);
	}
	.gwRowLayout {
		display: flex;
		flex-flow: row;
		align-items: stretch;
		align-content: flex-start;
		margin-bottom: var(--gw-header-row-margin-bottom);
	}
	.gwNoMessage {
		margin-top: var(--GW-SPACING-5);
	}
	.accountPhoneNumber {
		margin-top: var(--GW-LAYOUT-6);

		.accountDetailBold {
			color: var(--GW-LINK-COLOR);
		}
	}

	.accountDetailTitle {
		margin-bottom: var(--GW-LAYOUT-4);
	}

	.totalPremiumContainer {
		margin-top: var(--GW-LAYOUT-6);
	}

	.issuedPoliciesContainer {
		padding: var(--GW-LAYOUT-6);
		margin-top: var(--GW-LAYOUT-4);
	}
}
