:root{
    --gw-stay-on-page-bar-z-index: 2;
    --gw-timeline-filter-width: 100%;
    --gw-timeline-filter-dropdown-margin-right: 0;
    --gw-timeline-filter-dropdown-width: 20%;
    --gw-timeline-filter-years-padding: 0px;
}
.gwStayOnPageBar {
    z-index: var(--gw-stay-on-page-bar-z-index);
	position: relative;
	width: 100%;
}
.gwTimelineFilters {
    width: var(--gw-timeline-filter-width);
    justify-content: center;
    background: var(--GW-COLOR-SUCCESS-CONTRAST);
    display: flex;
    ul.gwTimelineFiltersYears {
        padding: var(--gw-timeline-filter-years-padding);
        display: flex;
        margin: 0;
    }
}
.gwTimelineFiltersJumpto {
    --gw-timeline-filter-jump-to-padding: var(--GW-LAYOUT-2) 0;
    flex: 1;
    padding: var(--gw-timeline-filter-jump-to-padding);
    color: var(--GW-TEXT-COLOR-2);
    display: flex;
}
.StayOnPageBarHashedStick {
    --gw-stay-on-page-bar-stick-border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
    --gw-stay-on-page-bar-stick-width: 92%;
    position: fixed;
    top: 0;
    border-bottom: var(--gw-stay-on-page-bar-stick-border-bottom);
    width: var(--gw-stay-on-page-bar-stick-width);
    z-index: 5;
    background: var(--GW-BACKGROUND-COLOR);
    padding: var(--GW-LAYOUT-3) 0;

}
.gwTimelineFilterYearsList {
    list-style: none;
    a {
        padding-left: var(--GW-SPACING-4);
        color: var(--GW-LINK-COLOR);
    }
}
.gwTimelineFiltersDropdown {
    --gw-timeline-filter-dropdown-padding: var(--GW-LAYOUT-2) 0;
    padding: var(--gw-timeline-filter-dropdown-padding);
    margin-right: var(--gw-timeline-filter-dropdown-margin-right);
    width: var(--gw-timeline-filter-dropdown-width);
}
