:root {
  --gw-space-for-padding: 3px;
  --gw-container-height: 4.4em;
  --gw-container-width: 1.2em;
  --gw-activity-padding-left: 90px;
  --gw-complete-margin-right: .25rem;
}

.addActivityGrid {
  padding-left: var(--gw-activity-padding-left);
}

.completeButton {
  margin-right: var(--gw-complete-margin-right);
}

.cssNoOfDueDays {
  color: var(--GW-COLOR-ERROR);
}

.gwPageHeader {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.gwPageHeaderRightPanel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.gwSpaceFor {
  padding-left: var(--gw-space-for-padding);
  padding-right: var(--gw-space-for-padding);
}

.buttonMarginStyle {
  margin-left: var(--gw-space-for-padding);
}

.gwRowLayout {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  align-content: flex-start;
}

.gwNoMessage {
  margin-top: var(--GW-LAYOUT-5);
}

.gwRowLayoutItem {
  display: flex;
}

.gwColumnLayout {
  margin-right: var(--GW-LAYOUT-3);
  margin-left: var(--GW-LAYOUT-3);
}

.addNewActivityContainerStyle {
  padding-left: var(--GW-LAYOUT-4);
  padding-right: var(--GW-LAYOUT-4);
  min-height: var(--gw-container-height);
  min-width: var(--gw-container-width);

  .spanStyle {
    width: 100%;
    text-align: right;
  }
}

.activityIcon {
  padding-left: var(--GW-SPACING-1);
  position: relative;
  top: var(--GW-SPACING-1);
}

.removeLinkStyle {
  text-decoration: none;
  color: var(--GW-LINK-COLOR);
  [class*="text"] {
    &:hover {
      text-decoration: none;
    }
  }
}
.gwHideCompleteButton {
  display: none;
}

.gwShowCompleteButton {
  display: block;
}

.field {
  text-transform: uppercase;
}

.gwActivityContent {
  padding-top: var(--GW-LAYOUT-5);
  border-top: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
}

.gwActivityTableNotesSeparator {
  margin-top: var(--GW-LAYOUT-8);
  border-top: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
}