
:root {
      --gw-transactions-filter-wid: 25%;
      --gw-transaction-filter-width: 280px;
      --gw-transactions-flex-grow: 2;
      --gw-total-value-width: 130px;
}

.gwTotalDetails {
      padding-bottom: var(--GW-LAYOUT-10);

      .gwTotalValueLabel {
            font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
            margin-top: var(--GW-SPACING-4);
      }

      .gwTotalValue {
            width: var(--gw-total-value-width);
            text-align: right;
      }
}

.gwTransactionsContainer {
      border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
      margin: 0 var(--GW-LAYOUT-4);
      padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
      .gwTransactionsContainerHeading {
            flex-grow: var(--gw-transactions-flex-grow);
      }
      .gwTransactionsFilter {
            width: var(--gw-transaction-filter-width);
      }
}

.gwViewOverall {
      padding-bottom: var(--GW-LAYOUT-3);
      margin-top: var(--GW-LAYOUT-3);
      cursor: pointer;
      color: var(--GW-LINK-COLOR);
      text-decoration: none;
}

.summaryDetails {
      border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
      margin: 0 var(--GW-LAYOUT-4);
      padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);

      .summaryDetailsSubHeading {
            font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
      }
}

.commissionTableContainer {
      .negative {
            [class*='currency'] {
                  color: var(--GW-COLOR-WARNING);
            }
      }
}