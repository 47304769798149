:root {
    --gw-header-padding: 275px;
    --gw-header-margin-left: 4px;
    --gw-button-padding: 10px;
}

.gwPageHeader {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.gwPageHeader_right_panel {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.gwRowLayout {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    align-content: flex-start;
    margin-bottom: var(--GW-LAYOUT-3);
}

.gwButtonMargin {
    margin-left: var(--gw-header-margin-left);
}

.gwRowLayoutItem {
    display: flex;
}

.gwColumnLayout {
    margin-right: var(--GW-LAYOUT-3);
    margin-left: var(--GW-LAYOUT-3);
}

.spanStyle {
   padding-left: var(--gw-header-padding);
}

.buttonForAddActivityStyle {
    padding-left: var(--gw-button-padding);
}
