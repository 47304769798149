@import "~@jutro/theme/assets/sass/helpers";

 .jut__ModalNext__modal {
   --gw-ve-modal-width: 45rem;
   --gw-modal-max-height: 90vh;

   width: var(--gw-ve-modal-width);
   > * {
     max-height: var(--gw-modal-max-height);
   }
 }