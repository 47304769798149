@import "~@jutro/theme/assets/sass/helpers";
:root {
	--gw-alert-flex: 1;
	--gw-alert-min-height: 38px;
	--gw-alert-max-height: 150px;
	--gw-alert-inner-margin: -16px 0;
	--gw-warning-flex-one: 1;
	--gw-warning-flex-big: 100000;
}

.underwritingContainer {
	margin-top: var(--GW-LAYOUT-4);
}

.totalCost {
	--gw-total-cost-padding: var(--GW-LAYOUT-4) 0;
	border-top: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR);
	padding: var(--gw-total-cost-padding);
}

.gwWarning {
	display: flex;
	flex-direction: row;
	margin-bottom: var(--GW-LAYOUT-4);
	margin-top: var(--GW-LAYOUT-4);
	padding: var(--GW-LAYOUT-4);
	border: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-WARNING);
	border-radius: var(--GW-BORDER-RADIUS);
	min-height: var(--gw-alert-min-height);
	background-color: var(--GW-COLOR-WARNING);
	button[class*='gwWarningIcon'] i {
		flex: 0 auto;
		display: inline-block;
		color: var(--GW-COLOR-WARNING-CONTRAST);
	}
}

.uwIssuesPanel {
	--gw-uwissues-panel-width: 100%;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	align-content: space-between;
	width: var(--gw-uwissues-panel-width);

	.gwWarningContent {
		flex: var(--gw-warning-flex-big) auto;
	}

	.gwWarningActionsInfo {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		align-content: center;
		flex-wrap: wrap;
		flex: 1 auto;

		@include gw-breakpoint-down(tablet) {
			padding-top: var(--GW-LAYOUT-6);
		}
	}
}

.gwMessageInfo {
	flex: var(--gw-warning-flex-one) auto;
	margin-right: var(--GW-LAYOUT-6);
	padding-top: var(--GW-LAYOUT-4);
}

.gwWarningIcon {
	flex: 0 auto;
	font-size: var(--GW-ICON-FONT-SIZE-3);
	margin-right: var(--GW-SPACING-5);
}

.gwInnnerInfo {
	display: flex;
	flex-flow: row wrap;
	align-content: space-between;
	padding-bottom: var(--GW-LAYOUT-4);
	margin: var(--gw-alert-inner-margin);
}

.actionBarUW {
	margin-left: var(--GW-LAYOUT-4);
}

.gwTitleSubtitleOffset {
	margin-bottom: var(--GW-LAYOUT-4);
}

.gwPageSectionHeaderRow {
	--gw-page-section-header-border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	border-bottom: var(--gw-page-section-header-border-bottom);
}

.gwReferToUnderwriterForm {
	display: flex;
}

.gwNoteForUnderwriter,
.gwReferralInfo {
	--gw-note-referral-width: 50%;
	--gw-note-referral-padding: 0 var(--GW-LAYOUT-8);
	flex: 1 0 auto;
	width: var(--gw-note-referral-width);
	padding: var(--gw-note-referral-padding);
}

.gwReferralButtons {
	display: flex;
	justify-content: flex-end;
}

.gwReferralButtonsOffset {
	margin-left: var(--GW-LAYOUT-2);
}
.gwWarningListParagraph {
	--gw-warning-list-paragraph-margin: 0px 0px -20px 0px;
	margin: var(--gw-warning-list-paragraph-margin);
}
.wishToContinue {
	margin-left: var(--GW-LAYOUT-2);
}
.gwUnderwritingIssueContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
}

.uwIssuesTitle {
	border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    padding: var(--GW-LAYOUT-4) 0;
}

