@import "~@jutro/theme/assets/sass/helpers";

:root {
      --gw-commission-chart-width: 23rem;
      --gw-commission-total-width: 15rem;
      --gw-policy-period-select-box-style-width: 3rem;
      --gw-policy-period-select-box-style-max-width: 100%;
      --gw-policy-period-select-box-label-width: 20rem;
      --gw-commision-summary-columns-tablet: 1fr;
}

.policyCommissionsSummary {
      line-height: var(--GW-LINE-HEIGHT);
      margin-top: var(--GW-LAYOUT-6);

      .viewCommission {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            .viewCommissionContainer {
                  display:flex;
                  flex-direction: column;
                  text-align: end;
                  .viewAccountCommission, .viewOverallCommission {
                        cursor: pointer;
                        color: var(--GW-LINK-COLOR);
                        text-decoration: none;
                  }
            }
      }
      .policyPeriodContainer{
            display: flex;
            justify-content: space-between;
      }
      .commissionSummaryDivider {
            margin: var(--GW-SPACING-10) 0;
            border-top: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
      }
      .producersColumn {
            --gw-policy-commission-padding-left: var(--GW-LAYOUT-10);
            @include gw-breakpoint-down(tablet) {
                  --gw-policy-commission-padding-left: 0px;
            }
            padding-left: var(--gw-policy-commission-padding-left);
            border-left: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
            width: 100%;
            .producerList {
                  margin-bottom: var(--GW-LAYOUT-8);
            }
            .title {
                  font-weight: var(--GW-FONT-WEIGHT-BOLD);
            }
      }
      .transactionsHeader {
            border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
      }
      .gwTotalDetails {
            display: flex;
            justify-content: flex-end;

            .gwTotalValueLabel {
                  font-weight: var(--GW-FONT-WEIGHT-BLACK);
                  margin-right: var(--GW-SPACING-3);
            }
      }

      .commissionWarning {
            margin-bottom: var(--GW-LAYOUT-10);
      }
      .commissionSummary {
            .commissionSummaryContainer {
                  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
                  padding-bottom: var(--GW-LAYOUT-3);

                  .subtitleDescription {
                        padding-bottom: var(--GW-SPACING-4);
                  }
            }
            .chartLine {
                  border-width: var(--GW-BORDER-WIDTH);
            }
            .summaryChart {
                  display: flex;
                  .chart {
                        flex-direction: row;
                        width: var(--gw-commission-chart-width);
                        margin-top: var(--GW-LAYOUT-8);
                        margin-bottom: var(--GW-LAYOUT-10);

                        .notPaid {
                              float: right;
                              .items {
                                    display: flex;
                                    flex-direction: column;
                              }
                        }

                        .paid {
                              float: left;
                              .items {
                                    display: flex;
                                    flex-direction: column;
                              }
                        }
                  }
                  .totalCommission {
                        width: var(--gw-commission-total-width);
                        margin: auto;
                        .items {
                              display: flex;
                              flex-direction: column;
                              margin: auto;
                        }
                  }
            }
      }
}

.gwTransactionsTableContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
    margin-top: var(--GW-LAYOUT-4);
}

.transactionTable {
      padding: 0;
      .negative {
            padding-bottom: 0;
            [class*='currency'] {
                  color: var(--GW-COLOR-ERROR);
            }
      }
}
.flexLine{
      display: flex;
      justify-content: space-between;
}
.summaryChart {
      hr[class*='chartLine'] {
            border: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
      }
}

div[class^="jut__FieldComponent__fieldComponent"].policyPeriodDropdown {
      display: grid;
      grid-template-columns: 2fr 3fr;
      grid-template-rows: auto;
      width: 100%;

      @include gw-breakpoint-down(tablet) {
            grid-template-columns: 2fr;
      }
}

.policyPeriodDropdown {
      label {
            width: var(--gw-policy-period-select-box-label-width);
      }
}

.totalCommissionValue {
      padding-bottom: 0;
      [class*='currency'] {
            font-size: var(--GW-FONT-SIZE-H5);
      }
}
