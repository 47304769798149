:root {
	--gw-change-policy-border: 1px;
	--gw-info-label-padding:0 1px 5px 0;
	--gw-info-label-margin: 0px;
}

.changePolicyLabel {
	color: var(--GW-TEXT-COLOR-2);
	margin-right: var(--GW-SPACING-2);
}

.changePolicyLeft {
	display: inline-block;
	padding-right: var(--GW-LAYOUT-2);
}

.changePolicyRight {
	border-left: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	padding-left: var(--GW-LAYOUT-2);
	display: inline-block;
}

.donnotCancel {
	padding-right: var(--GW-SPACING-6);
}

.infoMessage {
	background-color: var(--GW-COLOR-INFO);
	border-color: var(--GW-COLOR-INFO);
	color: var(--GW-TEXT-COLOR-2);
	padding: var(--GW-LAYOUT-4);
	margin-bottom: var(--GW-LAYOUT-10);
}

.notesTextareaBox {
	margin-bottom: var(--GW-LAYOUT-10);
}

.infolabelTitle {
	padding: var(--gw-info-label-padding);
}

.infolabelTitleDiv {
	border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	padding-bottom: var(--GW-LAYOUT-4);
	margin-bottom: var(--GW-LAYOUT-8);
	color: var(--GW-COLOR-INFO);
}

.btnAlignRight {
	margin-left: var(--GW-LAYOUT-1);
}
