@import "~@jutro/theme/assets/sass/helpers";
:root {
	--gw-hashed-timeline-max-width: 90%;
	--gw-hashed-timeline-width: 100%;
	--gw-hashed-timeline-content-width: 6px;
	--gw-hashed-timeline-fifty-percentage: 50%;
	--gw-hashed-timeline-content-margin-left: -3px;
	--gw-hashed-timeline-line-top: -20px;
}

.gwTimelineContainer {
	position: relative;
	max-width: var(--gw-hashed-timeline-max-width);
	margin: 0 auto;
	width: var(--gw-hashed-timeline-width);
    margin-top: var(--GW-LAYOUT-12);
    padding: var(--GW-LAYOUT-4);
    display: flex;
    flex-direction: column;
}

.accountTimelineContainer {
	padding: var(--GW-LAYOUT-4) var(--GW-LAYOUT-4) var(--GW-LAYOUT-8) var(--GW-LAYOUT-4);
}

.gwTimelineContainer::after {
	content: '';
	position: absolute;
	width: var(--gw-hashed-timeline-content-width);
	background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
	top: 0;
	bottom: 0;
	left: var(--gw-hashed-timeline-fifty-percentage);
	margin-left: var(--gw-hashed-timeline-content-margin-left);
}

.gwTimelineTopLine,
.gwTimelineBottomLine {
	position: relative;
	z-index: 1;
	display: flex;
	justify-content: center;
	border-top: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
	padding: var(--GW-LAYOUT-2);

	.calendarIcon {
		background: var(--GW-ACCENT-COLOR);
		position: absolute;
		z-index: 1;
		padding: var(--GW-SPACING-3);
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: var(--gw-hashed-timeline-fifty-percentage);
		top: var(--gw-hashed-timeline-line-top);
		border-top: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	}
}

.gwTimelineTopLine {
	padding-bottom: var(--GW-LAYOUT-4);
}
.gwTimelineBottomLine .calendarIcon {
	top: 0;
}

p.pageSectionDescription {
	margin: var(--GW-SPACING-4) 0;
}

@include gw-breakpoint-only(tablet) {
	.gwTimelineContainer::after {
		left: var(--GW-LAYOUT-8);
	}
	.gwTimelineTopLine, .gwTimelineBottomLine {
		--gw-timeline-top-bottom-line-left: -24rem;
		left: var(--gw-timeline-top-bottom-line-left);
	}
}
