:root {
	--gw-advanced-filter-margin-right: 20px;
	--gw-advanced-filter-width: 200px;
	--gw-filter-option-padding: 5px 10px 0px 7px;
	--gw-filter-select-link-margin-bottom: 8px;
	--gw-filter-select-link-margin-right: 8px;
	--gw-filter-select-link-padding: 0px 10px 0px 0px;
	--gw-filter-section-title-padding: 5px;
	--gw-clear-filter-link-margin-top: 5px;
}

.gwAdvancedFilter {
	background-color: var(--GW-TEXT-COLOR-3);
	margin-right: var(--gw-advanced-filter-margin-right);
	width: var(--gw-advanced-filter-width);
}

.gwFilterSection {
	flex-direction: column;
	width: var(--gw-advanced-filter-width);
}

.gwFilterOption {
	flex-direction: column;
	padding: var(--gw-filter-option-padding);
}

.gwFilterSelectLink {
	display: flex;
	flex-flow: row wrap;
	padding: var(--gw-filter-select-link-padding);

	a {
		flex: 1 1 auto;
		margin-bottom: var(--gw-filter-select-link-margin-bottom);
		margin-top: var(--gw-filter-select-link-margin-bottom);

		&:not(:last-child) {
			margin-right: var(--gw-filter-select-link-margin-right);
		}
	}
}

.gwFilterSectionTitle {
	background-color: var(--GW-LINK-COLOR-HOVER);
	padding: var(--gw-filter-section-title-padding);
	color: var(--GW-COLOR-SUCCESS-CONTRAST);
	text-transform: uppercase;
}

.gwClearFilterLink {
	margin-top: var(--gw-clear-filter-link-margin-top);
}

.gwActivityFilterOptionsLabel {
	width: auto;
}
