@import "~@jutro/theme/assets/sass/helpers";

:root {
  --gw-wizard-side-page-category-padding: var(--GW-LAYOUT-3);
  --gw-wizard-side-page-separator-margin: var(--GW-LAYOUT-1);
  --gw-wizard-side-page-separator-height: 18px;
}

.gwWizardPageTitle {
  margin: 0 0 var(--GW-LAYOUT-6) 0;

  .gwWizardPageCategory {
    margin-bottom: var(--GW-LAYOUT-2);
  }

  .gwWizardPageAssociated {
    color: var(--GW-LINK-COLOR);
    text-decoration: none;
    margin-left: var(--GW-SPACING-2);
  }
}

.gwWizardSidePageTitle {
  display: flex;

  .gwWizardSidePageCategory {
    padding-right: var(--gw-wizard-side-page-category-padding);
  }

  .gwWizardSidePageAssociated {
    color: var(--GW-FOCUS-COLOR);
    text-decoration: none;
  }

  .gwWizardSidePageTitleSeparator {
    margin-top: var(--gw-wizard-side-page-separator-margin);
    height: var(--gw-wizard-side-page-separator-height);
    border: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
  }

  .gwWizardSidepageTitle {
    padding-left: var(--GW-LAYOUT-3);
  }
}

h1.wizardPageHeader {
  @include gw-breakpoint-down(phonewide) {
    font-size: var(--GW-FONT-SIZE-H3);
  }
}

.gwPageTitle {
  color: var(--GW-TEXT-COLOR-1);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @include gw-breakpoint-up(tablet) {
    flex-direction: row;
  }
}

h1[class*='gwPageTitle'] {
  @include gw-breakpoint-down(phonewide) {
    font-size: var(--GW-FONT-SIZE-H3);
  }
  @include gw-breakpoint-up(tablet) {
    margin-bottom: var(--GW-SPACING-6);
  }
}
