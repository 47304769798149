
:root{
  --gw-spreadsheet-hashed-section: 100%;
--gw-spreadsheet-left-column-width: 10%;
--gw-spreadsheet-left-column-margin: 0 38 0 0;
--gw-spreadsheet-section-row-width: 55%;
--gw-spreadsheet-processor-column-right-width: 90%;
--gw-processor-step-icon: 40px;
--gw-spreadsheet-processor-left-flex-basis: 44%;
--gw-spreadsheet-processorupload-button-margin-top: 40px;
--gw-spreadsheet-processor-middle-flex-basis: 12%;
}
.spreadsheetProcessorHashedSection {
  display: flex;
  flex-flow: column;
  width: var(--gw-spreadsheet-hashed-section);
}

.spreadsheetProcessorContainer {
  display: flex;
  flex-direction: column;
}
.spreadsheetProcessorHeaderRow {
  display: flex;
  flex-flow: row nowrap;
  margin-top: var(--GW-LAYOUT-2);
}
.spreadsheetProcessorRow {
  width: var(--gw-spreadsheet-section-row-width);
  display: flex;
  flex-flow: row;
  align-self: center;
  margin-top: var(--GW-LAYOUT-2);
}

.spreadsheetProcessorColumnLeft {
  justify-content: center;
  display: flex;
  width: var(--gw-spreadsheet-left-column-width);
  margin: var(--gw-spreadsheet-left-column-margin);
}
.spreadsheetProcessorColumnRight {
    width: var(--gw-spreadsheet-processor-column-right-width);
    display: flex;
    flex-flow: column wrap;
}
.spreadsheetProcessorColumn {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  align-self: flex-start;
  margin-left: var(--GW-LAYOUT-6);
}
.stepActionIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: var(--gw-processor-step-icon);
  height: var(--gw-processor-step-icon);
  border-radius: var(--gw-processor-step-icon);
  flex: 0 0 var(--gw-processor-step-icon);
  border: var(--GW-BORDER-WIDTH) solid var(--GW-BRAND-COLOR-1);
  color: var(--GW-LINK-COLOR);
}
.spreadsheetProcessorGap {
  margin-bottom: var(--GW-LAYOUT-4);
}

.spreadsheetProcessorPara {
  margin: 0;

  .spreadSheetEnterManuallyButton {
    color: var(--GW-LINK-COLOR);
    padding-left: 0;

    &:hover {
      background: transparent;
      color: var(--GW-LINK-COLOR-HOVER);
    }
  }
}
.spreadsheetHorizontalDivider {
  height: 0;
  border: 0;
  border-top: 1px solid var(--GW-SEPARATOR-COLOR);
  margin: var(--GW-LAYOUT-5) 0;
}
.spreadsheetProcessorMiddle {
  flex-basis: var(--gw-spreadsheet-processor-middle-flex-basis);
}
.spreadsheetProcessorRight {
  flex-basis: var(--gw-spreadsheet-processor-left-flex-basis);
}

.faqLinkContainer {
  display: block;
}
.faqLinkStyle{
  text-decoration: none;
  color: var(--GW-LINK-COLOR);
  cursor: pointer;
}