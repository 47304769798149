.termCheckbox {
  display: flex;
  .link {
      text-decoration: underline;
  }
}
.userSignUpContainer {

    > * {
        margin-bottom: var(--GW-SPACING-XL);
        margin-top: var(--GW-SPACING-XL);
    }
  
    > * .userSignUpForm {
      margin-bottom: var(--GW-SPACING-XL);

    }

    button {
      box-shadow: 0px 0px 0px 0px;
    }
   
  }
