@import "~@jutro/theme/assets/sass/helpers";

.openActivitiesContainer {
  background: var(--GW-BACKGROUND-COLOR);
  border-radius: var(--GW-BORDER-RADIUS);
  box-shadow: var(--GW-SHADOW-2);
  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);

  div[class*=wizardTitle] {
    padding-top: 0;
  }
}

.gwPageHeader {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

}

div[class*='wizardTitle'].gwPageHeader {
  margin-bottom: 0;
}

.completeButton {
  margin-right: var(--GW-LAYOUT-10);
}

.gwPageHeaderRightPanel {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.gwRowLayout {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;
  align-content: flex-start;
}

.gwRowLayoutItem {
  display: flex;
}

.gwColumnLayout {
  --gw-column-layout-width: 224px;
  margin-right: var(--GW-LAYOUT-3);
  margin-left: var(--GW-LAYOUT-3);
  width: var(--gw-column-layout-width);
  @include gw-breakpoint-down(tablet) {
    --gw-column-layout-width: 180px;
  }
}

.openActivitiesContainer {
  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
}

.searchInputSize {
  --gw-seach-input-box-width: 224px;
  width: var(--gw-seach-input-box-width);
  @include gw-breakpoint-down(tablet) {
    --gw-seach-input-box-width: 180px;
  }
}

.gwTitleStyle {
  padding-bottom: var(--GW-LAYOUT-3);
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.addActivityPopUp {
  width: 60%;

  @include gw-breakpoint-down(tablet) {
      width: 80%;
  }

  div[class^='jut__ModalBody__modalBody'] {
      padding: var(--GW-LAYOUT-8) var(--GW-LAYOUT-6) var(--GW-LAYOUT-10) var(--GW-LAYOUT-6);
  }
}

.activityFirstGrid {
  padding-right: var(--GW-LAYOUT-6);
  border-right: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.activitySecondGrid {
  height: fit-content;
}