.gw-branding-millennials {
    --gw-application-header-height: 75px;
    body {
        button {
            --GW-BRAND-COLOR-1: var(--gw-pink-1);
            --GW-BRAND-COLOR-1-ACTIVE: var(--gw-pink-1);
            --GW-BRAND-COLOR-1-HOVER: var(--gw-pink-1);
        }
        a{
            color: var(--gw-pink-1);
            text-decoration: none;
        }
        a span:active,a span:hover{
            --GW-TEXT-DECORATION-UNDERLINE: none;
        }
        .jut__Button__tertiary{
            --GW-BRAND-COLOR-1: var(--gw-lime-green);
        }
        .buttonFileUpload button{
            --button-bg-color: var(--gw-pink-1);
            background-image: none;
            --button-border-color: none;
        }
        .digitalToggleButton {
            --toggleButton-color: var(--gw-grey-light-60-color);
            background-image: var(--toggleButton-bg-image);
            background-color: var(--toggleButton-bg-color);
        }
        .digitalAccordionToggle h2, #adjusterTitleDiv h2 {
            --gw-millennials-accordion-toggle-border-spacing: 2px;
            border-bottom: var(--gw-millennials-accordion-toggle-border-spacing) solid var(--gw-pink-4);
        }
        .actionIcon{
            --GW-BRAND-COLOR-1: var(--gw-lime-green);
            --GW-BRAND-COLOR-1-ACTIVE: var(--gw-green-2);
            --GW-BRAND-COLOR-1-HOVER: var(--gw-green-2);
        }
    }

    #applicationHeader {
        height: var(--gw-application-header-height);
        background-color: var(--gw-white-opaque-7);
        background-image: linear-gradient(to bottom, var(--gw-grey-light-62-color), var(--gw-white));

        span, a, i, select {
            color: var(--gw-pink-1);
        }
        .gw-app-header-img {
            --gw-app-header-img-width: 329px;
            --gw-app-header-img-height: 79px;
            .gw-app-logo {
                --gw-header-image: url("../../public/branding/millennial_logo.png");
            }
        }

        .startNewQuoteBtn {
            span, i {
                color: var(--gw-white);
            }
        }
    }

    .ReactTable .rt-thead {
        background-color: var(--gw-pink-1);
    }
    .ReactTable a, .ReactTable button{
        color: var(--gw-pink-1);
    }
    div[class^='MenuBarComponent_menuBar'] {
        --gw-menu-bar: var(--gw-violet-1);
        a {
            color: var(--gw-white);
        }
    }
    .gwTilesColor {
        background-color: var(--gw-pink-1);
    }
    .gwTileMainContent {
        a {
            color: var(--gw-grey-light-9);
        }
    }
    #newQuoteButton {
        --GW-BRAND-COLOR-1: var(--gw-lime-green);
        --GW-BRAND-COLOR-1-ACTIVE: var(--gw-green-2);
        --GW-BRAND-COLOR-1-HOVER: var(--gw-green-2);
    }

    div[class^='QuoteClauseTable_quoteTableHeaderSection'] a{
        color: var(--gw-blue-light-8);
    }
    .producerCodesBlock .inputFieldThin a, .jumpToNewest,.printConfirmationDiv a{
        color: var(--gw-blue-light-8);
    }
    #detailGridPolicyDeclationLinkId a,
    #detailGridPolicyIdCardLinkId a,
    #pageHeaderPolicyItem a,
    #useSpreadsheetDisclaimerContainer a,
    #successDetailsPage a,
    #spreadsheetProcessor a,
    #editCoveragesLink,
    #employeesAndLocationContainer a,
    #policyInfoLink a{
        color: var(--gw-blue-light-8);
    }
    .clauseCategoryContainer button {
        color: var(--gw-blue-light-2);
    }
}