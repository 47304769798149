@import "~@jutro/theme/assets/sass/helpers";

.gwInfolabelLabelStyle {
    --info-label-color: var(--GW-TEXT-COLOR-2);
}

.gridPaddingLeft{
    padding-bottom: var(--GW-SPACING-5);
    grid-template-columns: 0 3fr;
}

.containerStyle {
    padding-left: var(--GW-LAYOUT-5);
}

.buttonContainer {
    display: flex;
}

.checkBoxChange {
    padding-top: var(--GW-SPACING-6);
    align-items: flex-start;
}

.vehicleMakeModelLabel {
    @include gw-breakpoint-up(tablet) {
        --gw-min-width-vehicle-make-model: 157px;
        min-width: var(--gw-min-width-vehicle-make-model);
    }
    font-size: var(--GW-FONT-SIZE-H5);
    width: max-content;
    padding-right: var(--GW-SPACING-2);
}

.vehicleYearLabel {
    --gw-width-vehicleYear-label: 100%;
    width: var(--gw-width-vehicleYear-label);
}

.driverNameLabel{
    --gw-width-driverName-label: 100%;
    font-size: var(--GW-FONT-SIZE-H5);
    width: var(--gw-width-driverName-label)
}

.inlineNotificationStyle {
    margin: var(--GW-SPACING-6) 0;
}

.cancelButton {
    float: right;
}

.iconMargin{
    margin-right: 5px;
}

.titleRight {
    clear: both;
    @include gw-breakpoint-down(phonewide) {
        flex-direction: row;
    }
}

.generalCoverageStyle {
    font-size: var(--GW-FONT-SIZE-LABEL);
    color: var(--GW-TEXT-COLOR-2);
}

h2[class='policyChangeSubHeader'] {
    margin-bottom: var(--GW-SPACING-4);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    @include gw-breakpoint-down(phonewide) {
        font-size: var(--GW-FONT-SIZE-H3);
    }
}

h3[class='policyChangeSubSubHeader'] {
    margin-bottom: var(--GW-SPACING-4);
    @include gw-breakpoint-down(phonewide) {
        font-size: var(--GW-FONT-SIZE-H4);
    }
}
