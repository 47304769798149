.removeLinkStyle {
    color: var(--GW-LINK-COLOR);
    text-decoration: none;
}

.quotedBoundContainer {
    div[class*='jut__FieldLabel__left'] {
        flex: 2 1;
    }
}

.summaryDetailGrid {
    margin-bottom: var(--GW-LAYOUT-8);
}
