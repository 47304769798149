

:root {
    --gw-premium-grid-template-column: 12fr 1fr;

}

.gwCoverages {
    margin: var(--GW-LAYOUT-4) 0;
}

.toggleVehicleInfo {
    text-decoration: none;
    text-align: right;
    justify-content: right;
    background-color: transparent;
    color: var(--GW-LINK-COLOR);

    &:hover {
        color: var(--GW-LINK-COLOR-HOVER);
    }
}

.totalPremiumRightAlign {
    display: grid;
    justify-items: right;
    text-align: right;
    grid-template-columns: var(--gw-premium-grid-template-column);
}

.vehicleLicencePlate {
    --gw-vehicle-licence-plate-flex-value: 108px;
    div:first-child{
        flex: 0 1 var(--gw-vehicle-licence-plate-flex-value);
    }
}

.gwCoveragesContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
    margin-bottom: var(--GW-LAYOUT-6);
}
