@import "~@jutro/theme/assets/sass/helpers";

.confirmationContainer {
  text-align: center;
  margin: calc(6 * var(--GW-LAYOUT-4));

  @include gw-breakpoint-down(phonewide) {
    margin: 0;
  }

  .confirmationCheckIcon {
    color: var(--GW-COLOR-SUCCESS);
    margin-bottom: var(--GW-LAYOUT-6);
  }

  .confirmationCheckIcon {
    --gw-confirmation-check-icon-height-width: 140px;
    --gw-confirmation-check-icon-border-radius: 50%;
    height: var(--gw-confirmation-check-icon-height-width);
    width: var(--gw-confirmation-check-icon-height-width);
    color: var(--GW-TEXT-COLOR-3);
    background-color: var(--GW-COLOR-SUCCESS);
    border-radius: var(--gw-confirmation-check-icon-border-radius);
    margin: var(--GW-LAYOUT-6) auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .confirmationIcon {
    --gw-confirmation-icon-size: 6rem;

    line-height: inherit;
    font-size: var(--gw-confirmation-icon-size);
  }

  .confirmationText {
    font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    margin-bottom: var(--GW-SPACING-4);
  }

  .confirmationInfoText {
    font-weight: var(--GW-FONT-WEIGHT-REGULAR);
    margin-bottom: var(--GW-SPACING-6);
  }
}
