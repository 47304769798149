:root {
    --gw-timeline-dropdown-policy-width: 85%;
    --gw-timeline-dropdown-policy-z-index: 2;
    --gw-timeline-dropdown-policy-left: -1.95rem;
    --gw-timeline-dropdown-policy-top: -29px;
    --gw-timeline-dropdown-menu-wrapper-padding: 10px 0;
    --gw-timeline-dropdown-menu-wrapper-height: 500px;
    --gw-timeline-dropdown-menu-wrapper-link-width: 100%;
    --gw-policy-dropdown-menu-wrapper-width: 200px;
}
.gwTimelineDropdownPolicy {
    width: var(--gw-timeline-dropdown-policy-width);
    i {
        z-index: var(--gw-timeline-dropdown-policy-z-index);
        left: var(--gw-timeline-dropdown-policy-left);
        top: var(--gw-timeline-dropdown-policy-top);
    }
}
.gwTimelineDropdownButton {
    --gw-timeline-dropdown-button-width: 190px;
    width: var(--gw-timeline-dropdown-button-width);
}
.chevronIcon {
    margin-left: var(--GW-SPACING-8);
}

.gwPolicyDropdownMenuWrapper {
    width: var(--gw-policy-dropdown-menu-wrapper-width);
    border: none;
    border-radius: 0;
    left: auto;
    transform: translateY(10px);
    padding: var(--gw-timeline-dropdown-menu-wrapper-padding);
    min-width: 0;
    height: var(--gw-timeline-dropdown-menu-wrapper-height);
    overflow: scroll;
    a {
        width: var(--gw-timeline-dropdown-menu-wrapper-link-width);
        cursor: pointer;
        left: 0;
        span{
            display: block;
            padding: 0;
            background-color: transparent;
            span:hover{
                text-decoration: underline;
            }
        }
        &:hover{
            background: transparent;
        }
    }
}