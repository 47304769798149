@import "~@jutro/theme/assets/sass/helpers";
:root {
  --gw-coverage-section-bottom-border-width: 1px;
  --gw-policy-details-summary-section-top: 1;
  --gw-policy-details-summary-section-bottom: 2;
}

.coverageValues {
  @include gw-breakpoint-down(tablet) {
    height: var(--GW-LINE-HEIGHT-MD);
  }
}

.app__Link__link {
  text-decoration: var(--GW-NO-TEXT-DECORATION);
  color: var(--GW-LINK-COLOR);

  &:visited {
      color: var(--GW-LINK-COLOR);
  }

  &:hover span {
      text-decoration: var(--GW-TEXT-DECORATION-UNDERLINE);
  }

  &:active {
      text-decoration: var(--GW-TEXT-DECORATION-UNDERLINE);
  }
}

.gwColumnLayout {
  --column-layout-width: 224px;

  @include gw-breakpoint-down(tablet) {
    --column-layout-width: 180px;
  }

  width: var(--column-layout-width);
}

.transactionHeader {
  margin-bottom: var(--GW-LAYOUT-2);
}

.gwOverrideDefaultMargin {
  margin: 0;
}

.gwRowLayout {
  margin-bottom: var(--GW-LAYOUT-6);
}

.gwSummaryExtraLink {
  color: var(--GW-LINK-COLOR);
  text-decoration: none;
}

.gwSummaryExtraLinkSpace {
  margin-bottom: var(--GW-SPACING-3);
}

.gwCoveragesContainer {
  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
}

.gwCoverages {
  margin: var(--GW-LAYOUT-4) 0;
}
.gwCoverageSection:not(:last-child) {
  margin-bottom: var(--GW-LAYOUT-4);
  padding-bottom: var(--GW-LAYOUT-4);
  border-bottom: var(--gw-coverage-section-bottom-border-width) solid var(--GW-BORDER-COLOR);
}

.gwTerms {
  grid-template-columns: 1fr 1fr;
}
.gwTermTitle {
  text-align: right;
}
.billingSummarySeparator {
  --gw-billing-summary-separator-width: 100%;
  width: var(--gw-billing-summary-separator-width);
  margin: var(--GW-LAYOUT-4) 0;
  border: none;
  border-top: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR);
}
.inputFieldThin {
  --input-field-height: 24px;
  height: var(--input-field-height);
}
.billingCurrencyRightAlign {
  text-align: right;
}
.customerPaymentSummaryStyle {
  color: var(--GW-TEXT-COLOR-2);
}
.additionalAccordionContainer {
  margin: var(--GW-LAYOUT-6) 0;
  .title {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
  }
  .producerOfServiceValueStyle {
    white-space: nowrap;
  }
  .summaryDetailBottom {
    @include gw-breakpoint-down(tablet) {
      grid-row: var(--gw-policy-details-summary-section-bottom);
    }
  }
  .summaryDetailTop {
    --summary-detail-margin-bottom: 64px;
    @include gw-breakpoint-down(tablet) {
    grid-row: var(--gw-policy-details-summary-section-top);
     margin-bottom: var(--summary-detail-margin-bottom);
    }
  }
}

.summaryDetailGrid {
  grid-column-gap: var(--GW-LAYOUT-6);
  > div {
    .summaryDetails, .producerOfServiceValueStyle {
      display: flex;
      flex-direction: row;
      div[class*='jut__FieldLabel__fieldLabelContainer'] {
        display: contents;
      }
      div[class*='jut__FieldLabel__fieldLabelContainer'] > label::after{
        content: ':';
      }
      div[class*='jut__FieldComponent__contentContainer'] {
        padding-left: var(--GW-LAYOUT-1);
      }
    }
    background: var(--GW-BACKGROUND-COLOR);
    border-radius: var(--GW-BORDER-RADIUS);
    box-shadow: var(--GW-SHADOW-2);
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
  }
}

.gwPolicyTransactionContainer {
  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
}

.gwTitleStyle {
  padding-bottom: var(--GW-LAYOUT-3);
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.searchInputSize {
  --search-input-width: 224px;
  width: var(--search-input-width);
  @include gw-breakpoint-down(tablet) {
    --search-input-width: 180px;
  }
}

.jobNumberColumnLink {
  display: contents !important;
}

.payrollReportMessage {
  border: 1px solid;
  padding: var(--GW-SPACING-6);
  margin-top: var(--GW-SPACING-6);
  margin-bottom: var(--GW-SPACING-6);
}

.policyWarningMessage{
  margin-bottom: var(--GW-SPACING-6);
}

.employerAssessmentValuesMargin{
  margin-bottom: var(--GW-LAYOUT-10);
}

.premiumTablePadding {
  padding: 0;
  text-align: left;
}
