.gwAssignedToText {
  --gw-assigned-text-border-radius: var(--GW-BORDER-RADIUS) 0 0 0;

  border: solid 1px transparent;
  border-radius: var(--gw-assigned-text-border-radius);
  border-right: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.gwEditActivityButton {
  --gw-assigned-icon-button-height: var(--GW-ICON-BUTTON-HEIGHT);

  border: solid 1px transparent;
  line-height: var(--GW-LINE-HEIGHT);
  display: none;
  max-height: var(--gw-assigned-icon-button-height);
}
div[class*='rt-tr-group'] {
  div[class*='rt-tr']:not(:only-child) {
    .gwReassignActivityDisplay {
      &:hover {
        .gwEditActivityButton {
          display: block;
        }
      }
    }
  }
}

.gwReassignActivityWrapper {
  --gw-reassign-activity-wrapper-width: 100%;

  width: var(--gw-reassign-activity-wrapper-width);
  position: relative;
  display: flex;
  align-items: center;

  .gwEditActivityButton {
    color: var(--GW-BRAND-COLOR-1);
    display: none;
  }
}
.activityReassignClass {
  --gw-activity-reassign-container-width: 100%;

  width: var(--gw-activity-reassign-container-width);
  // FIXME after jutro datatable expander issue solved
  overflow: visible !important;
}
.gwReassignActivityConfirmation {
  display: flex;
  flex-direction: row-reverse;
}
.gwReassignConfirmationButtons {
  i {
    color: var(--GW-BRAND-COLOR-1);
  }
}
.gwReassignConfirmationButtons:first-child {
  border-bottom-left-radius: var(--GW-BORDER-RADIUS);
  border-right: 0;
}
