@import "~@jutro/theme/assets/sass/helpers";

:root {
  --gw-payment-content-size: 100%;
  --gw-payment-icon-border-radius: 50%;
  --gw-payment-icon-height: 33px;
  --gw-payment-icon-width: 33px;
}

.gwGridRow {
  --gw-grid-row-margin-left: -15px;
  --gw-grid-row-margin-right: -15px;
  display: flex;
  margin-left: var(--gw-grid-row-margin-left);
  margin-right: var(--gw-grid-row-margin-right);
}

.gwGridIcon {
  --gw-grid-icon-border-radius: 50%;
  --gw-grid-icon-width: 33px;
  --gw-grid-icon-height: 33px;
  border-radius: var(--gw-grid-icon-border-radius);
  width: var(--gw-grid-icon-width);
  height: var(--gw-grid-icon-height);
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
  border-color: var(--GW-SEPARATOR-COLOR);
  display: flex;
  justify-content: center;
  align-items: center;
}

.gwPaymentColumnInfo {
  padding-left: var(--GW-LAYOUT-12);
}

.paymentInfoWrapper {
  display: flex;
  flex-flow: row nowrap;
  padding-top: var(--GW-SPACING-8);
}

.paymentBoxContainer {
  --gw-payment-box-container-flex-basis: 44%;
  flex-basis: var(--gw-payment-box-container-flex-basis);
}

.paymentBoxContent {
  --gw-payment-box-content-border-width: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--GW-BORDER-RADIUS);
  border: var(--gw-payment-box-content-border-width) solid var(--GW-SEPARATOR-COLOR);
}

.payContainer {
  font-size: var(--GW-FONT-SIZE-H4);
  margin: var(--GW-SPACING-5) 0;
  text-align: center;
}

.gwPaymentButtonWrapper {
  --gw-pay-button-wrapper-width: 210px;
  --gw-pay-button-wrapper-size: 100%;
  width: var(--gw-pay-button-wrapper-width);
  padding: 0 var(--GW-SPACING-6) var(--GW-SPACING-5) var(--GW-SPACING-6);
  .gwPaymentButtonContent {
    height: var(--gw-payment-content-size);
    width: var(--gw-pay-button-wrapper-size);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .gwPaymentBoxContent {
    text-align: center;
    width: var(--gw-payment-content-size);
  }
}

.gwSeparatorContainer {
  --gw-pay-separator-flex: 12%;
  --gw-pay-separator-width: 16px;
  --gw-pay-separator-border: 1px;
  flex-basis: var(--gw-pay-separator-flex);
  align-self: center;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  color: var(--GW-BORDER-COLOR);
  line-height: 1;

  .gwSeparator {
    --gw-pay-separator-size: 1px;
    display: inline-block;
    margin: var(--GW-SPACING-2);
    width: var(--gw-pay-separator-width);
    min-width: var(--gw-pay-separator-width);
    border-bottom: var(--gw-pay-separator-border) solid var(--GW-SEPARATOR-COLOR);
    font-size: var(--gw-pay-separator-size);
    vertical-align: middle;
  }
}
.gwWarningIcon {
  flex: 0 auto;
  font-size: var(--GW-ICON-FONT-SIZE-3);
  padding-right: var(--GW-SPACING-10);
  color: var(--GW-COLOR-WARNING);
}
.uwIssuesPanel {
  --gw-uwissues-panel-width: 100%;
  --gw-warning-flex-one: 1;
  display: flex;
  width: var(--gw-uwissues-panel-width);
  .gwWarningContent {
    flex: var(--gw-warning-flex-one) auto;
    width: var(--gw-uwissues-panel-width);
    .gwWarningContentHeading {
      font-size: var(--GW-FONT-SIZE-LABEL);
      font-weight: var(--GW-FONT-WEIGHT-REGULAR);
      line-height: var(--GW-LINE-HEIGHT-XS);
      color: var(--GW-BORDER-COLOR);
    }
    .gwWarningContentInfo {
      font-size: var(--GW-FONT-SIZE-LABEL);
      margin-bottom: 0;
    }
  }
  .gwWarningActionsInfo {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
  }
}
.gwWarning {
  --gw-alert-min-height: 38px;
  --gw-alert-max-height-desktop: 150px;
  display: flex;
  flex-direction: row;
  margin-bottom: var(--GW-LAYOUT-12);
  padding: var(--GW-SPACING-5);
  border-radius: var(--GW-BORDER-RADIUS);
  min-height: var(--gw-alert-min-height);
  font-size: var(--GW-FONT-SIZE-LABEL);
  background-color: var(--GW-COLOR-WARNING);
  border-color: var(--GW-COLOR-WARNING);
  max-height: var(--gw-alert-max-height-desktop);

  @include gw-breakpoint-down(tablet) {
    max-height: none
  }

  .gwWarningIcon {
    i {
      flex: 0 auto;
      display: inline-block;
      font-size: var(--GW-ICON-FONT-SIZE-3);
      color: var(--GW-COLOR-WARNING-CONTRAST);
    }
  }
}
.gwReferralButtons {
  display: flex;
  justify-content: flex-end;
}
.gwNoteForUnderwriter,
.gwReferralInfo {
  --gw-note-referral-width: 50%;
  --gw-note-referral-margin-top: -6px;
  flex: 1 0 auto;
  width: var(--gw-note-referral-width);
  padding: 0 var(--GW-SPACING-6);
  display: flow-root;
  margin-top: var(--gw-note-referral-margin-top);
}
.gwReferToUnderwriterForm {
  display: flex;
}
.gwReferralButtonsOffset {
  margin-left: var(--GW-SPACING-1);
}
.iconClass {
  border-radius: var(--GW-BORDER-RADIUS);
  width: var(--gw-quoteContainer-icon-width);
  height: var(--gw-quoteContainer-icon-height);
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
  display: flex;
  justify-content: center;
  align-items: center;
}
.policyPeriodStyle {
  white-space: nowrap;
  div[class*="startDateValue"] {
    width: auto;
  }
}
.totalPremiumStyle {
  span[class*="currency"] {
    font-size: var(--GW-FONT-SIZE-H2);
  }
}

.stickyNoteStyle {
  padding-top: var(--GW-SPACING-2);
}

.headerSeparator {
  padding: var(--GW-SPACING-3);
}