
:root{
--gw-timeline-array-term-min-width: 112px;
--gw-timeline-array-definition-max-width: 100%;
}

gwTimelineDefinitionArray {
  display: flex;
  flex-direction: column;
  width:100%;
  margin-bottom: var(--GW-LAYOUT-3);
  border-top: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);

  &__term {
    margin-bottom: var(--GW-LAYOUT-3);
    color: var(--GW-TEXT-COLOR-2);
    margin-top: var(--GW-LAYOUT-5);
  }

  &__list {
    display: flex;
    flex-flow: column wrap;
  }

  &__definition {
    max-width: var(--gw-timeline-array-definition-max-width);
    margin-bottom: var(--GW-LAYOUT-1);
    margin-right: var(--GW-LAYOUT-3);
    color: var(--GW-TEXT-COLOR-1);
    word-wrap: break-word;
  }
}

