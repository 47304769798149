@import "~@jutro/theme/assets/sass/helpers";

.gwMediaSidebarWrapper {
    --gw-media-sidebar-width: 100%;
    --gw-media-sidebar-height: 75%;

    max-width: var(--gw-media-sidebar-width);
    max-height: var(--gw-media-sidebar-height);
    background-color: var(--GW-BACKGROUND-COLOR);
    @include gw-breakpoint-down(tablet) {
        display:none;
    }

    div {
        width: 100%;
    }
}

#player{
    width: 100%;
}
