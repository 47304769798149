:root {
    --gw-contact-roles-margin: 2px;
    --gw-contact-roles-padding: 2rem;
    --gw-contact-role-header-padding-bottom: 10px;
    --gw-contact-role-container-padding-left: 10px;
}

.gwContactRoleHeader {
    padding-bottom: var(--gw-contact-role-header-padding-bottom);
}

.gwContactRoleContainer {
    display: flex;
    padding-bottom: var(--GW-LAYOUT-3);
    margin: var(--gw-contact-roles-margin) 0;

    .gwContactRoleTitle {
        color: var(--GW-TEXT-COLOR-1);
        height: auto;
        &:after {
			content: '\003a';
			padding-right: var(--GW-LAYOUT-1);
		}
    }
    .gwContactRoleValue {
        font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
    }
}
