@import "../NewQuoteAccountSearch.module.scss";

.existingAccountBorder {
    margin: var(--GW-LAYOUT-10) 0;
    border: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}
.personalContainer,
.quoteContainer,
.contactTypeContainer {
  margin-bottom: var(--GW-LAYOUT-4);
}