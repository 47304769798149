@import "~@jutro/theme/assets/sass/helpers";

:root {
	--gw-timeline-container-width: 45%;
	--gw-timeline-container-left: 5%;
	--gw-timeline-container-right: 50%;
	--gw-timeline-event-header-icon-right: -43px;
	--gw-timeline-event-icon-right: -55px;
	--gw-timeline-container-content-right: 30px;
	--gw-timeline-container-left-content-border-width: 10px 0 10px 10px;
	--gw-timeline-container-right-content-border-width: 10px 10px 10px 0;
	--gw-timeline-container-right-content-left: -16px;
	--gw-timeline-container-responsive-width: 100%;
	--gw-timeline-container-responsive-container-right-left: 0%;
}

.gwTimelineContainer {
	--gw-timeline-container-padding: var(--GW-LAYOUT-3) var(--GW-LAYOUT-10);
	padding: var(--gw-timeline-container-padding);
	position: relative;
	background-color: inherit;
	width: var(--gw-timeline-container-width);
}

.gwTimelineContainerLeft {
	left: var(--gw-timeline-container-left);
	.gwTimelineEventHeader__icon {
		right: var(--gw-timeline-event-header-icon-right);
		left: auto;
	}
	.gwTimelineEventIcon {
		right: var(--gw-timeline-event-icon-right);
		left: auto;
	}
}

.gwTimelineContainerRight {
	left: var(--gw-timeline-container-right);
}

.gwTimelineContainerLeft::before {
	content: " ";
	height: 0;
	position: absolute;
	top: var(--GW-LAYOUT-6);
	width: 0;
	z-index: 1;
	right: var(--gw-timeline-container-content-right);
	border: var(--GW-BORDER-WIDTH) solid var(--GW-COLOR-SUCCESS-CONTRAST);
	border-width: var(--gw-timeline-container-left-content-border-width);
	border-color: transparent transparent transparent var(--GW-ACCENT-COLOR);
}

.gwTimelineContainerRight::before {
	--gw-timeline-container-right-content: 22px;
	content: " ";
	height: 0;
	position: absolute;
	top: var(--gw-timeline-container-right-content);
	width: 0;
	z-index: 1;
	left: var(--gw-timeline-container-content-right);
	border-style: solid;
	border-width: var(--gw-timeline-container-right-content-border-width);
	border-color: transparent var(--GW-SEPARATOR-COLOR) transparent transparent;
}

.gwTimelineContainerEventColor {
	&--personalauto {
		&::before {
			border-color: transparent var(--GW-SEPARATOR-COLOR) transparent var(--GW-SEPARATOR-COLOR);
		}
		&:hover {
			&::before {
				border-color: transparent var(--GW-SEPARATOR-COLOR) transparent var(--GW-SEPARATOR-COLOR);
			}
		}
		&__lineIcon {
			color: var(--GW-ACCENT-COLOR);
		}
	}
	&--homeowners {
		&::before {
			border-color: transparent var(--GW-SEPARATOR-COLOR) transparent var(--GW-SEPARATOR-COLOR);
		}
		&:hover {
			&::before {
				border-color: transparent var(--GW-SEPARATOR-COLOR) transparent var(--GW-SEPARATOR-COLOR);
			}
		}
		&__lineIcon {
			color: var(--GW-ACCENT-COLOR);
		}
	}
}

.gwTimelineContainerRight::after {
	left: var(--gw-timeline-container-right-content-left);
}

.gwTimelineContent {
	background-color: var(--GW-ACCENT-COLOR);
	position: relative;
}

@include gw-breakpoint-only(tablet) {
	.gwTimelineContainer {
		--gw-timeline-container-padding-left: 72px;
		width: var(--gw-timeline-container-responsive-width);
		padding-left: var(--gw-timeline-container-padding-left);
		padding-right: var(--GW-LAYOUT-6);
	}
	.gwTimelineContainer::before {
		--gw-timeline-container-left: 63px;
		left: var(--gw-timeline-container-left);
		border: var(--GW-BORDER-WIDTH) solid;
		border-width: var(--gw-timeline-container-right-content-border-width);
		border-color: transparent var(--GW-SEPARATOR-COLOR) transparent transparent;
	}
	.gwTimelineContainerLeft {
		left: 0%;
	}
	.gwTimelineContainerRight {
		left: var(--gw-timeline-container-responsive-container-right-left);
	}
}