@import "~@jutro/theme/assets/sass/helpers";

:root {
  --gw-producer-engage-container-max-width: 90em;
  --gw-producer-engage-container-width: 90%;
  --gw-producer-table-icon-spa: 4px;
  --gw-main-container-padding: 180px;
  --gw-sub-title-padding-bottom: var(--GW-SPACING-4);
  --gw-sub-title-border: 2px;
  --gw-tablet-sidebar-width: 20rem;
}
html {
  --gw-header-image: url("../../public/branding/logo-version-agents.svg");
  --gw-header-mobile-image: url("../../public/branding/logo-mobile.svg");
  height: 100%;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  > div#root {
    height: 100%;
    > div {
      height: 100%;
    }
  }
}

.producerEngageContainer {
  padding-bottom: var(--gw-main-container-padding);
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
  >div {
    padding-top: var(--GW-SPACING-10);
  }
}
.inlineWithIndent {
  >* {
    display: inline-block;
    &:not(:last-child) {
      margin-right: var(--GW-SPACING-4);
    }
  }
}
.gwTitle {
  font-weight: var(--GW-FONT-WEIGHT-REGULAR);
}

.gwSubtitle {
  font-size: var(--GW-FONT-SIZE-H3);
  font-weight: var(--GW-FONT-WEIGHT-SEMI-BOLD);
  line-height: var(--GW-LINE-HEIGHT-H3);
  padding-bottom: var(--gw-sub-title-padding-bottom);
  border-bottom: var(--gw-sub-title-border) solid var(--GW-BORDER-COLOR);
}

.globalizationChooser {
  padding: 0 var(--GW-SPACING-4);
  select {
    -webkit-appearance: menulist;
    -moz-appearance: menulist;
  }
  span {
    visibility: hidden;
  }
}
table[class*='tableElement'] {
  a {
    text-decoration: none;
    color: var(--GW-LINK-COLOR);
  }
  i {
    margin-right: var(--gw-producer-table-icon-spa);
  }
}
// theme
$fonts-root: "../assets/fonts";
// ------------------------------------
//   GROUNDED CSS MODULES
// ------------------------------------
@import "~@jutro/theme/assets/fonts/fonts";
@import "~@jutro/theme/groundedStyles";

// ----------------------------------------
//   BRANDING CSS MODULES
// ----------------------------------------
@import "../styles/branding/gateway-millennials-demo.scss";
@import "../styles/branding/gateway-cobranded-demo.scss";

@import "~gw-capability-gateway-react/gateway-styles";

.jut__FieldComponent__left .jut__FieldLabel__left {
  flex: 1 1 0;
  align-items: flex-start;
  height: auto;
  text-align: left;

  .jut__FieldLabel__fieldLabel {
    margin-right: var(--GW-SPACING-4);
  }
}

.jut__Accordion__accordionToggle {
  height: auto;
}

.tilesContainer {
  padding-bottom: calc(1.25 * var(--GW-SPACING-3));
}

.jut__ModalNext__modal {
  --gw-modal-max-height: 90vh;
  > * {
    max-height: var(--gw-modal-max-height);
  }
}
