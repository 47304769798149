@import "~@jutro/theme/assets/sass/helpers";

.gwAlert {
	margin-bottom: var(--GW-LAYOUT-6);
	border: 1px solid transparent;
	border-radius: var(--GW-BORDER-RADIUS);

	.gwAlertIcon {
		--gw-success-notification-component-icon-size: 1.5rem;
		font-size: var(--gw-success-notification-component-icon-size);
	}
}

.gwAlertInfo {
	background-color: var(--GW-HIGHLIGHT-COLOR-1);
	border-color: var(--GW-SEPARATOR-COLOR);
	padding: var(--GW-LAYOUT-4);

	.gwAlertIcon {
		color: var(--GW-BRAND-COLOR-2);
	}
}

.gwAlertWarning {
	background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
	border-color: var(--GW-BORDER-COLOR);
	padding: var(--GW-LAYOUT-4);

	.gwAlertIcon {
		color: var(--GW-COLOR-WARNING);
	}
}

.gwAlertContent {
	--gw-alert-content-width: 100%;

	width: var(--gw-alert-content-width);
	padding-left: var(--GW-LAYOUT-2);
}

.gwActionsInfo {
	text-align: right;

    @include gw-breakpoint-down(tablet) {
		text-align: left;
		margin-top: var(--GW-LAYOUT-4);
		margin-left: var(--GW-LAYOUT-10);
    }
}

.continueTransaction {
	margin-left: var(--GW-LAYOUT-4);
}
