.gwProducerCodeDivider {
  --gw-divider-border: 1px;

  display: inline-block;
  border-left: solid var(--gw-divider-border) var(--GW-SEPARATOR-COLOR);
  padding-right: var(--GW-SPACING-6);
  margin-left: var(--GW-SPACING-6);
}

.gridSectionTitle {
  text-align: center;
}

.inputFieldThin {
  width: 100%;

  :global(.jut__FieldComponent__controls) {
    display: contents;
  }
}

.costContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.totalDue {
  border-top: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR);
  padding-top: var(--GW-SPACING-4);
}

.gridContainer {
  background-color: var(--GW-BACKGROUND-COLOR-ELEMENT);
  padding: var(--GW-LAYOUT-4);
  margin-bottom: var(--GW-LAYOUT-6);
}

.endorsementPolicyPeriodDate{
  div[class*='datePicker'] {
    padding-top: var(--GW-SPACING-6);
    font-size: var(--GW-FONT-SIZE-H2);
  }
  text-align: center;
}

.iconStyle {
  padding-top: var(--GW-SPACING-6);
  padding-bottom: var(--GW-SPACING-6);
  padding-right: var(--GW-SPACING-3);
}

.policyChangeDateContainerStyle {
  align-items: center;
  align-content: center;
  display: flex;
}

.policyDetailsContainer {
  margin-bottom: var(--GW-LAYOUT-6);
}

.policyDetailsSectionTitle {
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
  padding: var(--GW-SPACING-5) 0;
}
