.gwContactsTableContainer {
  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);

  .gwTitleStyle {
    padding-bottom: var(--GW-LAYOUT-2);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    margin-bottom: var(--GW-LAYOUT-2);
  }
}

.contactsContainer {
  margin-bottom: var(--GW-LAYOUT-10);
}