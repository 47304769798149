.gwPageSectionHeaderRow {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.gwPageSectionHeaderrowNewQuoteSection {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: var(--GW-SPACING-8);
}

.addOrChangeLink {
  text-decoration: none;
  color: var(--GW-LINK-COLOR);
  white-space: nowrap;
  &:hover {
    text-decoration: none;
  }
}

.coverageAddOrChangeContainerStyle {
  white-space: nowrap;
}