.searchResultsPage {
    h2.sectionHeader {
      margin-bottom: var(--GW-SPACING-4);
      border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
      padding-bottom: var(--GW-SPACING-4);
    }

    .searchResult {
      padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
    }

    .policyResultsContainer{
      display: flex;
      flex-direction: column;
      align-items: start;
      padding:var(--GW-LAYOUT-4);
    }  

    .policyResultsList{
       display:flex;
       flex-wrap: wrap;
    }

}