:root {
	--gw-loader-wrapper-large-min-height: 4.4em;
	--gw-loader-wrapper-large-min-width: 1.2em;
}

.claimCoverageStyle {
	margin: 0 auto var(--GW-LAYOUT-1) 0px;
}

.gwInlineBackNav {
	margin-bottom: var(--GW-LAYOUT-6);
	.policyTitle {
		margin-bottom: 0;
	}
	.gwBackToClaim {
		justify-self: start;
	}
}

.policySectionStyle {
	padding-bottom: var(--GW-SPACING-5);
	border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	margin-bottom: var(--GW-SPACING-10);
}

.gwInlineBackNav {
	position: relative;
}

.backToHomeImage {
	vertical-align: top;
	max-width: 100%;
	height: auto;
	vertical-align: middle;
	border: 0;
}

