:root {
  --gw-payment-content-size: 100%;
}

.gwQuoteGrid {
  margin-top: var(--GW-LAYOUT-8);
  padding-bottom: var(--GW-LAYOUT-1);
}

.gwGridRow {
  display: flex;
  margin-left: var(--GW-LAYOUT-4);
  margin-right: var(--GW-LAYOUT-4);
}

.gwGridIcon {
  --gw-grid-icon-border-radius: 50%;
  --gw-grid-icon-width: 33px;
  --gw-grid-icon-height: 33px;
  border-radius: var(--gw-grid-icon-border-radius);
  width: var(--gw-grid-icon-width);
  height: var(--gw-grid-icon-height);
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
  border-color: var(--GW-SEPARATOR-COLOR);
  display: flex;
  justify-content: center;
  align-items: center;
}

.changeInCostStyle {
  span[class*="currency"] {
    font-size: var(--GW-FONT-SIZE-H2);
  }
}

.gwPaymentColumnInfo {
  padding-left: var(--GW-LAYOUT-2);
}

.paymentInfoWrapper {
  padding-top: var(--GW-LAYOUT-8);
}

.paymentBoxContainer {
  --gw-payment-box-container-flex-basis: 44%;
  flex-basis: var(--gw-payment-box-container-flex-basis);
}

.paymentBoxContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: var(--GW-BORDER-RADIUS);
  border: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
}

.payContainer {
  margin: var(--GW-LAYOUT-4) 0 var(--GW-LAYOUT-4) 0;
  text-align: center;
}

.gwPaymentButtonWrapper {
  --gw-pay-button-wrapper-width: 210px;
  --gw-pay-button-wrapper-size: 100%;
  width: var(--gw-pay-button-wrapper-width);
  padding: 0 var(--GW-LAYOUT-6) var(--GW-LAYOUT-4) var(--GW-LAYOUT-6);
  .gwPaymentButtonContent {
    height: var(--gw-payment-content-size);
    width: var(--gw-pay-button-wrapper-size);
  }

  .gwPaymentBoxContent {
    text-align: center;
    width: var(--gw-payment-content-size);
  }
}

.gwSeparatorContainer {
  --gw-pay-separator-flex: 12%;
  --gw-pay-separator-width: 16px;

  flex-basis: var(--gw-pay-separator-flex);
  align-self: center;
  color: var(--GW-SEPARATOR-COLOR);
  line-height: 1;

  .gwSeparator {
    --gw-pay-separator-size: 1px;

    display: inline-block;
    margin: var(--GW-SPACING-3);
    width: var(--gw-pay-separator-width);
    min-width: var(--gw-pay-separator-width);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
    font-size: var(--gw-pay-separator-size);
    vertical-align: middle;
  }
}

.periodChange {
  --gw-period-change-width: 200px;
  width: var(--gw-period-change-width);
  white-space: nowrap;
}