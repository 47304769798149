:root {
    --gw-contacts-name-padding : 3px;
    --gw-contacts-details-section-border: 1px;
}

.gwSubTitle {
    padding-bottom: var(--GW-LAYOUT-4);
}

.gwRowLayout {
    display: inline-flex;

    .gwAccountContactStatus {
        padding-left: var(--GW-LAYOUT-3);
        line-height: var(--GW-LINE-HEIGHT);
    }
}

.gwRowLayoutItem {
    .gwSourceState {
        font-size: var(--GW-FONT-SIZE-H3);
        font-weight: var(--GW-FONT-WEIGHT-REGULAR);
        padding-right: var(--GW-LAYOUT-3);
    }

    .gwSourceState:hover {
        color: var(--GW-BRAND-COLOR-1-HOVER);
        cursor: pointer;
    }
}


.gwContactRolesContainer, .gwContactDetailsSectionLeft {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);

    .gwContactSectionHeader {
        margin-bottom: var(--GW-SPACING-4);
    }
}

.gwCompanyDetailContainer {
    padding-bottom: var(--GW-LAYOUT-3);
}


.gwEmphasis {
    line-height: var(--GW-LINE-HEIGHT);
    color: var(--GW-TEXT-COLOR-1);
    margin: 0;

    &:after {
        content: '\003a';
        padding-right: var(--GW-SPACING-2);
    }
}
.contactInputData {
    width: fit-content;
    margin: 0;
}

.gwCompanyDetailHeader {
    color: var(--GW-TEXT-COLOR-2);
    display: block;
    height: auto;
}

.gwCompanySectionContainer {
    --gw-contacts-company-margin: 30px;

    margin-bottom: var(--gw-contacts-company-margin);
}

.gwAssociatedAccountsContainer{
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);

    .gwAssociatedAccountsHeader {
        margin-bottom: var(--GW-SPACING-4);
    }

    .gwAssociatedAccountsSection {
        margin-bottom: var(--GW-LAYOUT-3);
    }
}
