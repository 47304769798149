:root {
    --gw-title-margin-left: -0.05em;
    --gw-warnning-info-text-max-width: 620px;
    --gw-activity-title-height: 50px;
    --gw-filter-type-title-margin-top: -7px;
    --gw-activity-info-advanced-table-width: 80%;
    --gw-activity-info-quick-table-width: 100%;
    --gw-filter-wrapper-padding-top: 2px;
}

.activities {
  .gwRowActivity {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    align-content: flex-start;
  }

  .gwActivitiesFilterContainer {
    padding-top: var(--GW-LAYOUT-4);
  }

  .gwActivitiesFilterWrapper {
    padding-left: var(--GW-LAYOUT-2);

    a {
      span:hover {
        text-decoration: none;
      }
    }
  }

  .gwFilterTypeTitle {
    cursor: pointer;
    text-decoration: none;
    color: var(--GW-LINK-COLOR);
    margin-top: var(--gw-filter-type-title-margin-top);
  }
  .gwQuickFilterTableContainer {
    width: var(--gw-activity-info-advanced-table-width);
  }
  .gwAdvancedFilterTableContainer {
    width: var(--gw-activity-info-quick-table-width);
  }
  .gwRowLayout {
    display: flex;
    flex-flow: row wrap;
    align-items: stretch;
    align-content: flex-start;
    margin-bottom: var(--GW-LAYOUT-6);
  }
}

.gwActivitiesTableWrapper {
  padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
}


.gwTitleStyle {
  padding-bottom: var(--GW-LAYOUT-3);
  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.gwActivitiesPageTileContainer {
  padding-top: var(--GW-LAYOUT-1);
}