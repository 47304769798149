@import "~@jutro/theme/assets/sass/helpers";

:root {
  --gw-box-padding: 25px;
  --gw-label-left-padding: 58px;
  --gw-icon-top-padding: 50px;
  --gw-icon-right-padding: 45px;
  --gw-date-left-padding: 100px;
  --gw-right-padding-space: 36px;
}

.gwProducerCodeDivider {
  display: inline-block;
  border-left: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR);
  padding-right: var(--GW-SPACING-6);
  margin-left: var(--GW-SPACING-6);
}

.inputFieldThin {
  width: 100%;

  :global(.jut__FieldComponent__controls) {
    display: contents;
  }
}

.costContainer {
  display: flex;
  align-items: center;
  width: 100%;
}

.totalDue {
  border-top: solid var(--GW-BORDER-WIDTH) var(--GW-BORDER-COLOR);
}

.gridContainer {
  background-color: var(--GW-BACKGROUND-COLOR-BODY);
  padding: var(--GW-LAYOUT-6);
}

.iconStyle {
  --gw-icon-top-padding: 45px;
  padding-top: var(--gw-icon-top-padding);
}

.endorsementPolicyPeriodDateLabelClass{
  text-align: center;

}

.policyChangeDateContainerStyle {
  align-items: center;
  align-content: center;
}

.hoDateIdClassName {
  text-align: center;
}

h3[class='quotePageSubHeader'] {
  --gw-section-title-spacing: var(--GW-SPACING-5);

  border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
  margin-bottom: var(--GW-SPACING-3);
  padding: var(--GW-SPACING-5) 0;
}
