

:root{
  --gw-timeline-definition-diff-width: 40%;
  --gw-timeline-definition-diff-diff-tablet-padding-left: 0;
  --gw-timeline-definition-diff-diff-desktop-padding-left: 0;
}

.gwTimelineDefinitionDiff {
  display: flex;
  margin-bottom: var(--GW-LAYOUT-3);
  flex-wrap: wrap;
  width: var(--gw-timeline-definition-diff-width);

  &__term {
    color: var(--GW-TEXT-COLOR-2);
    padding-bottom: var(--GW-LAYOUT-1);
    padding-right: var(--GW-LAYOUT-3);
  }

  &__diff {
    flex-direction: column;
    display: flex;
    width: 100%;
    word-break: break-word;
  }

  &__new,
  &__old {
    color: var(--GW-TEXT-COLOR-1);
    flex-grow: 1;
    width: 100%;
  }

  &__old {
    text-decoration: line-through;
    color: var(--GW-TEXT-COLOR-2);
  }
}

