.rssStyle {
    padding: var(--GW-LAYOUT-2);
    background: var(--GW-COLOR-SUCCESS-CONTRAST);
    border-bottom: 1px solid var(--GW-COLOR-SUCCESS-CONTRAST);

    a {
        text-decoration: none;
    }

    .muted {
        color: var(--GW-TEXT-COLOR-1);
        font-size: var(--GW-FONT-SIZE-BODY);
    }
}
