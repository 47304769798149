@import "~@jutro/theme/assets/sass/helpers";
.pageContainer {
	margin-top: var(--GW-LAYOUT-8);
}
.policySectionStyle {
	padding-bottom: var(--GW-SPACING-5);
	border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
	margin-bottom: var(--GW-SPACING-10);
}
.coverageTitle {
	padding: var(--GW-SPACING-5);
}
.coverageDetailsContainer {
	margin-top: var(--GW-LAYOUT-4);
	background-color: var(--GW-COLOR-SUCCESS-CONTRAST);
}


.toggleLocationInfo {
    text-decoration: none;
    text-align: right;
    justify-content: right;
    color: var(--GW-TEXT-COLOR-1);

    &:hover {
		color: var(--GW-TEXT-COLOR-1-HOVER);
		text-decoration: none;
    }
}
