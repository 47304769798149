@import "~@jutro/theme/assets/sass/helpers";

.gwPageHeaderRightPanel {
    display: flex;
    justify-content: space-between;
}

.gwColumnLayout{
    --gw-accounts-gwColumnLayout-width: 224px;
    margin-left: var(--GW-LAYOUT-3);
    margin-right: var(--GW-LINE-HEIGHT-XS);
    width: var(--gw-accounts-gwColumnLayout-width);
    @include gw-breakpoint-down(tablet) {
      --gw-accounts-gwColumnLayout-tab-width: 180px;
      width: var(--gw-accounts-gwColumnLayout-tab-width);
    }
}

.iconMargin {
    margin-top: var(--GW-SPACING-2);
}

.currencyContainer {
  div[class*="fieldComponent"] {
    padding-bottom: 0;
  }
}

.gwAccountClaimContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8) var(--GW-LAYOUT-6);
}

.gwTitleStyle {
    padding: var(--GW-LAYOUT-3);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}
.searchInputSize {
    --search-input-width: 224px;
    width: var(--search-input-width);
    @include gw-breakpoint-down(tablet) {
        --search-input-tab-width: 180px;
        width: var(--search-input-tab-width);
    }
}