
:root {
	--gw-spreadsheet-upload-result-report-width: 100%;
	--gw-spreadsheet-process-result-alert-min-height: 50px;
	--gw-result-sub-heading-width: 100%;
	--gw-alert-type-error-content-width: 100%;
	--gw-action-message-inside-margin: -16px 0;
	--gw-result-action-message-content-flex: 100000 auto;
	--gw-result-action-message-content-width: 100%;
	--gw-result-wrapper-grid-template-columns: 30% 70%;
	--gw-resultbreadcrumb-height: 20px;
}

.spreadsheetProcessorResultBody {
	padding: var(--GW-LAYOUT-10) 0 var(--GW-LAYOUT-8) var(--GW-LAYOUT-8);
}

.gwResultAlert {
	display: flex;
	flex-direction: row;
	margin-bottom: var(--GW-LAYOUT-12);
	padding: var(--GW-SPACING);
	border: 1px solid transparent;
	border-radius: var(--GW-BORDER-RADIUS);
	min-height: var(--gw-spreadsheet-process-result-alert-min-height);
}

.gwResultAlertIconTypeError {
	color: var(--GW-COLOR-ERROR);
}
.gwResultAlertIconTypeInfo {
	color: var(--GW-COLOR-INFO);
}
.gwResultAlertIconTypeWarning {
	color: var(--GW-COLOR-WARNING);
}
.gwResultAlertIcon {
	flex: 0 auto;
	font-size: var(--GW-ICON-FONT-SIZE-3);
	padding-right: var(--GW-SPACING-4);
}

.gwAlertContent {
	flex: 1 auto;
	width: var(--gw-alert-type-error-content-width);
}

.gwResultActionMessage {
	display: block;
}

.gwActionMessageInsides {
	display: flex;
	flex-flow: row wrap;
	align-content: space-between;
	padding-bottom: var(--GW-SPACING-4);
	margin: var(--gw-action-message-inside-margin);
}

.gwResultActionMessageContent {
	flex: var(--gw-result-action-message-content-flex);
	margin-right: var(--GW-SPACING-6);
	padding-top: var(--GW-SPACING-4);
	width: var(--gw-result-action-message-content-width);
}

.gwResultTitlesHeading {
	font-weight: var(--GW-FONT-WEIGHT-REGULAR);
	line-height: var(--GW-LINE-HEIGHT);
}

.gwResultSubheading {
	margin: var(--GW-SPACING-4) 0 var(--GW-SPACING-6) 0;
	width: var(--gw-result-sub-heading-width);
}

.gwResultContents {
	margin-bottom: var(--GW-SPACING-6);
}

.gwResultSummary {
	margin: var(--GW-SPACING-4) 0 var(--GW-SPACING-6) var(--GW-SPACING-8);
	.section {
		display: flex;
		align-items: center;
		margin-bottom: var(--GW-SPACING-3);
		.icon {
			min-width: var(--GW-ICON-FONT-SIZE-3);
			margin-right: var(--GW-SPACING-4);
			font-size: var(--GW-ICON-FONT-SIZE-3);
			.success {
				color: var(--GW-COLOR-SUCCESS);
			}
			.error {
				color: var(--GW-COLOR-ERROR);
			}
		}
		.iconSpacing {
			min-width: var(--GW-ICON-FONT-SIZE-3);
			margin-right: var(--GW-SPACING-4);
		}
		.report {
			width: var(--gw-spreadsheet-upload-result-report-width);
			.linkDisabled {
				cursor: not-allowed;
				pointer-events: none;
				color: var(--GW-TEXT-COLOR-2);
			}
			.errors {
				.errorDescriptor {
					color: var(--grey-dark-60-color);
				}
				.errorDescription {
					line-height: var(--GW-LINE-HEIGHT-XS);
				}
			}
		}
	}
}

.gridResultWrapper {
	display: grid;
	grid-template-columns: var(--gw-result-wrapper-grid-template-columns)
}

.gwResultBreadcrumbs {
	--gw-result-less-link-margin-left: 50px;
	display: block;
	margin-left: var(--gw-result-less-link-margin-left);
	&item {
		display: inline-block;
		&:not(:first-child) {
			margin-left: var(--GW-SPACING-4);
			&::before {
				content: '';
				display: inline-block;
				vertical-align: middle;
				border-left: 1px solid var(--GW-BORDER-COLOR);
				width: 0;
				height: var(--gw-resultbreadcrumb-height);
				margin-right: var(--GW-SPACING-4);
			}
		}
	}
}

.successInstructionList {
	margin: var(--GW-SPACING-4) 0 var(--GW-SPACING-10) 0;
	.reminderInfo {
		display: block;
	}
}