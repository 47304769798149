@import "~@jutro/theme/assets/sass/helpers";

:root {
    --gw--map-label-margin-bottom: 10px;
}

.lossLocationContainer {
    .addressLinesStyle {
        align-items: normal;
    }
    .infoLableIconClass{
        --gw-icon-top-spacing: 2px;
        margin-right: var(--GW-SPACING-1);
        margin-top: var(--gw-icon-top-spacing);
        position: absolute;
    }
    .mapInfoLableClass {
        margin-left: var(--GW-SPACING-6);
    }

}
