.gwQuoteDetailsContainerBox {
  --gw-quote-details-width: 50%;
  display: flex;
  padding: var(--GW-LAYOUT-10) 0 0;
  justify-content: space-between;
  align-items: stretch;
}

.gwInnerQuoteBoxLeft {
  width: var(--gw-quote-details-width);
}

.gwInnerQuoteBoxRight {
  --gw-quote-details-inner-box-width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: var(--gw-quote-details-inner-box-width);
}

.gwQuoteTotalHR {
  --gw-quote-total-width: 100%;

  border-top: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
  margin: var(--GW-SPACING-3) 0;
  width: var(--gw-quote-total-width);
  border-bottom: none;
}
