:root {
  --gw-quoteContainer-margin: 0 auto;
  --gw-quoteContainer-max-width: 50em;
  --gw-pageTitle-margin: 0 0 40px 0;
  --gw-buttonFooter-width: 100%;
  --gw-buttonFooter-margin-top: 15px;
  --gw-buttonFooter-margin-left: 1%;
  --gw-newCustomerBtnStyle-margin-left: 3%;
  --gw-toggleContainer-height: 100px;
  --gw-buttonCustomLinkAlign-margin-bottom: 2.2%;
  --gw-toggleReverse-width: 67%;
}
.quoteContainer {
  margin: var(--gw-quoteContainer-margin);
  max-width: var(--gw-quoteContainer-max-width);

  .pageTitle {
    margin: var(--gw-pageTitle-margin);
  }
}

.buttonFooter {
  width: var(--gw-buttonFooter-width);
  text-align: right;
  margin-top: var(--gw-buttonFooter-margin-top);
}

.buttonStyle {
  margin-left: var(--gw-buttonFooter-margin-left);
}

.newCustomerBtnStyle {
  margin-left: var(--gw-newCustomerBtnStyle-margin-left);
}

.toggleContainer {
  height: var(--gw-toggleContainer-height);
}

.buttonCustomLinkAlign {
  margin-bottom: var(--gw-buttonCustomLinkAlign-margin-bottom);
}

.toggleReverse {
  width: var(--gw-toggleReverse-width);
}