.dateContainer {
    display: flex;
    align-items: flex-end;
}
.date {
    width: 100%;
}
.expirationYear {
    width: 100%;
    padding-left: var(--GW-SPACING-2);
}
