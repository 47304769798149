@import "~@jutro/theme/assets/sass/helpers";

.gw-app-header {
  --gw-app-header-img-width: 186px;
  --gw-app-header-img-height: 48px;

  @include gw-breakpoint-down(tablet) {
    --gw-app-header-img-width: 58px;
  }
}

.gw-app-header-img span{
  width: var(--gw-app-header-img-width);
  height: var(--gw-app-header-img-height);
  display: grid;
  cursor: pointer;

  .gw-app-logo {
    background-image: var(--gw-header-image);
    background-size: contain;
    background-repeat: no-repeat;
  }

  .gw-app-logo-mobile {
    background-image: var(--gw-header-mobile-image);
    background-size: contain;
    background-repeat: no-repeat;
  }

  @include gw-breakpoint-up(tablet) {
    .gw-app-logo-mobile {
      display: none;
    }
    .gw-app-logo {
      display: block;
    }
  }

  @include gw-breakpoint-down(tablet) {
    .gw-app-logo-mobile {
      display: block;
    }
    .gw-app-logo {
      display: none;
    }
  }
}