@import "~@jutro/theme/assets/sass/helpers";

@include gw-breakpoint-down(tablet) {
  .employeeClassesWordWrap {
    word-break: break-word;
  }
}

.employeeClasses {
  --employee-class-width: 9.6rem;
  @include gw-breakpoint-down(tablet) {
    --employee-class-width: 7.5rem;
  }
  width: var(--employee-class-width);
}

.annualWages {
  --annual-wage-width: 4rem;
  @include gw-breakpoint-down(tablet) {
    --annual-wage-width: 3rem;
  }
  width: var(--annual-wage-width);
}

.addEmployeeButton {
  --add-employee-min-width: 4.5rem;
  min-width: var(--add-employee-min-width);
}

.noOfEmployees {
  --no-of-employee-class-width: 4rem;
  @include gw-breakpoint-down(tablet) {
    --no-of-employee-class-width: 3rem;
  }
  width: var(--no-of-employee-class-width);
}

.employeeState {
    --employee-class-width: 9.6rem;
    @include gw-breakpoint-down(tablet) {
      --employee-class-width: 7.5rem;
    }
    width: var(--employee-class-width);
}

.anyOptions {
  --any-options-width: 3rem;
  width: var(--any-options-width);
}

.wc7AddMoreButtonContainer {
  --gw-button-container-padding: var(--GW-SPACING);
  padding-top: var(--gw-button-container-padding);
}

.labelStyle {
  white-space: nowrap;
}
