@import "~@jutro/theme/assets/sass/helpers";

.wc7EmployeesWrapper {
    display: flex;
    flex-flow: row nowrap;
    padding-top: var(--GW-LAYOUT-8);
}

.wc7EmployeesContainer {
    --gw-cp-initial-screen-flex-basis: 44%;

    flex-basis: var(--gw-cp-initial-screen-flex-basis);
}

.wc7EmployeesSummary {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: var(--GW-BORDER-RADIUS);
    border: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.wc7HeaderSummaryInfo {
    margin: var(--GW-SPACING-4) 0;
    text-align: center;
}

.wc7SummaryInfoWrapper {
    padding-bottom: var(--GW-LAYOUT-4);
}

.wc7NeedMoreInfoSection {
    color: var(--GW-LINK-COLOR);

    &:hover {
        text-decoration: none;
        color: var(--GW-LINK-COLOR-HOVER);
    }
}

.wc7SeparatorContainer {
    --gw-cp-separator-flex: 12%;

    flex-basis: var(--gw-cp-separator-flex);
    align-self: center;
    display: flex;
    line-height: var(--GW-LINE-HEIGHT-SUBTEXT);

    .wc7Separator {
        --gw-wc-seprator-width: 1rem;

        margin: var(--GW-LAYOUT-2);
        width: var(--gw-wc-seprator-width);
        border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    }
}

.wc7UseSpreadsheetDisclaimer {
    display: inline-block;
}

.wc7UseSpreadsheetDisclaimerRedirect {
    color: var(--GW-LINK-COLOR);
    text-decoration: underline;
    padding-left: 0;

    &:hover {
        background: transparent;
        color: var(--GW-LINK-COLOR-HOVER);
    }
}

.wc7PageHeaderRightPanel {
    display: flex;
    width: 100%;
    justify-content: space-between;
}
