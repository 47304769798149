@import "~@jutro/theme/assets/sass/helpers";

.policybilling {
  .policyBillingIcon {
    font-size: var(--GW-ICON-FONT-SIZE-3);
    padding: var(--GW-SPACING-4) var(--GW-SPACING-5) 0 0;
  }

  .billingSummaryTotalContainer {
    --gw-account-billing-total-value-container-width: 100%;

    width: var(--gw-account-billing-total-value-container-width);
  }

  .billingSummaryTotalCosts {
    --gw-customer-payment-summary-due-width: 100%;

    width: var(--gw-customer-payment-summary-due-width);
  }

  .billingCustomerPaymentSummaryTotalDue {
    --gw-customer-payment-summary-due-width: 80%;

    width: var(--gw-customer-payment-summary-due-width);
  }

  .billingSummaryNextPaymentContainer,
  .tabBillingSummaryPastDueContainer {
    display: flex;
    width: inherit;
    @include gw-breakpoint-down(tablet) {
      display: block;
    }
  }

  .negativePastDueAlert {
    color: var(--GW-COLOR-ERROR);
  }

  .totalCostAndDueLabel {
    color: var(--GW-TEXT-COLOR-1-HOVER);
    @include gw-breakpoint-down(tablet) {
      text-align: left;
    }
  }

  .billingSummarySeparator {
    border-top: var(--GW-BORDER-WIDTH) solid var(--GW-SEPARATOR-COLOR);
    margin: var(--GW-LAYOUT-3) 0;
    width: 100%;
    border-bottom: none;
  }

  .policyBillingDelinquent {
    color: var(--GW-COLOR-WARNING);
  }

  .policyBillingGoodStanding {
    color: var(--GW-COLOR-SUCCESS);
  }

  .tabBillingHeaderSep {
    margin: var(--GW-LAYOUT-4) 0 var(--GW-LAYOUT-4) 0;
  }

  .pastDue, .nextPayment{
    flex: 4;
    text-align: right;
    [class*='currency'] {
      color: var(--GW-COLOR-ERROR);
    }
  }

  .tabBillingSummaryDivider {
    --gw-divider-margin: 1.25rem;
    --gw-divider-padding: 1.25rem;
    --gw-divider-border: 1px;
    display: inline-block;
    border-left: solid var(--gw-divider-border) var(--GW-SEPARATOR-COLOR);
    padding-right: var(--gw-divider-padding);
    margin-left: var(--gw-divider-margin);
    margin-bottom: var(--gw-divider-margin);
  }

  .gwMessageContainer {
    display: flex;
    flex-flow: row;
    align-items: stretch;
    align-content: flex-start;
  }

  .gwDisplayNoMessage {
    --gw-display-nomessage-margin-top: -2px;
    --gw-display-nomessage-margin-left: 6px;
    margin-top: var(--gw-display-nomessage-margin-top);
    margin-left: var(--gw-display-nomessage-margin-left);
  }

  .tabBillingSummaryPaymentPlanStyle {
    float: right;
  }

  .tabBillingSummaryTotalPremium {
    div[class*='CurrencyField'] {
      float: right;
    }
  }

  .tabBillingSummaryPastDueLabel {
    width: max-content;
  }

  .tabBillingSummaryNextPaymentLabel {
    width: max-content;
  }

  .tabBillingSummaryPastDueDateSpan {
    flex: 1;
    text-align: right;
    float: right;
  }

  .gwTitleStyle {
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
    padding-bottom: var(--GW-LAYOUT-4);
  }

  .policyPeriodDropDown {
    --policy-period-width: 336px;
    width: var(--policy-period-width);
  }

  .billingInfoContainer {
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
  }
  .customerPaymentSummary,
  .tabBillingSummaryGrid {
    background-color: var(--GW-BACKGROUND-COLOR-BODY);
    padding: var(--GW-LAYOUT-6) var(--GW-LAYOUT-6) var(--GW-LAYOUT-8);
    margin-bottom: var(--GW-LAYOUT-5);
  }
  .tabBillingSummaryTotalDue {
    --billing-total-due-margin: 56px;
    text-align: right;
    @include gw-breakpoint-down(tablet) {
      div:last-child {
        margin-left: var(--billing-total-due-margin);
      }
    }
  }

  .policyPeriodSeparator {
    margin: var(--GW-LAYOUT-3) 0 var(--GW-LAYOUT-6);
    border-top-width: var(--GW-BORDER-WIDTH);
  }

  .policyPeriodTitle {
    margin-bottom: 0;
  }
  .totalDisputeWarning {
    margin-bottom: var(--GW-LAYOUT-4);
  }
  .policyWarningMessage {
    margin-bottom: var(--GW-LAYOUT-4);
  }
}
