@import "~@jutro/theme/assets/sass/helpers";

.gridPaddingLeft{
    padding-bottom: var(--grid-padding-bottom);
    grid-template-columns: 0fr 3fr;
}

.checkBoxChange {
    padding-top: var(--GW-SPACING-5);
    align-items: flex-start;

}

h3[class='policyChangeSubHeader'] {
    margin-bottom: var(--GW-SPACING-3);
    border-bottom: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}
