@import "~@jutro/theme/assets/sass/helpers";

.wizardContainer {
    --wizard-template-width-desktop: 80%;

    width: var(--wizard-template-width-desktop);
    margin: auto;

    @include gw-breakpoint-down(phonewide) {
        --pe-wizard-template-width-tablet-phone: 100%;

        width: var(--pe-wizard-template-width-tablet-phone);
        margin: 0;
    }

    div[class*='wizardMain'] {
        --gw-wizard-page-main-width: 100%;
    }
}
