@import "~@jutro/theme/assets/sass/helpers";

.editContactDetailsModal {
    width: 60%;

    @include gw-breakpoint-down(tablet) {
        width: 80%;
    }

    div[class^='jut__ModalBody__modalBody'] {
        padding: var(--GW-LAYOUT-8) var(--GW-LAYOUT-6) var(--GW-LAYOUT-10) var(--GW-LAYOUT-6);
    }
}

.accountDetailGrid {
    padding-right: var(--GW-LAYOUT-6);
    border-right: var(--GW-BORDER-WIDTH) solid var(--GW-BORDER-COLOR);
}

.accountDetailRightGrid {
    height: fit-content;
}