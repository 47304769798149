:root {
  --gw-success-details-page-max-width: 90em;
}

.successDetailsPage {
  margin: 0 auto;
  max-width: var(--gw-success-details-page-max-width);
}

.successDetailsContainer {
  padding: var(--GW-LAYOUT-10) var(--GW-LAYOUT-12);
}

.linkStyle {
    text-decoration: none;
    color:var(--GW-LINK-COLOR);
}

.removeLinkStyle {
  text-decoration: none;
  color:var(--GW-LINK-COLOR);
}

.dateRangeContainer {
  div[class*='startDateValue'] {
    --gw-start-date-range-width: none;
  }
}